import React, { useState, useEffect } from 'react'

import './book-tracker.css'
// import booksRead from '../../data/books/books-read.json'
// import bookUsers from '../../data/books/books-library.json'

export default function BookTracker() {
    const [booksPerPage, setBooksPerPage] = useState(25)
    const [books, setBooks] = useState([]);
    const [booksRead, setBooksRead] = useState([]);
    const [booksUsers, setBooksUsers] = useState([]);

    useEffect(() => {
        console.log('loading...');
        const fetchData = async () => {
            console.log('here');
            const response = await fetch('/data/books/books-library.json', { headers: { 'Accept': 'application/json' }  });
            console.log(response);
            const apiBooks = await response.json();
            console.log('done.');
            console.log({apiBooks});
            setBooks(apiBooks);
        }
        fetchData()
    }, []);


    function renderGeneratedStubs(booksPerPage) {
        let books = [];
        for (let i = 0; i < booksPerPage; i++) {
            books.push(renderBookTile({ title: `${i + 1} Book` }))
        }
        console.log(books);
        return books;
    }

    function renderBookTiles(books, perPageCount) {
        return books.map((book, idx) => renderBookTile(book, idx))
    }

    function renderBookTile(book) {
        return (
            <div className='book-tile'>
                {book.title}
            </div>
        )
    }

    return (
        <div className="book-container">
            {console.log(books.length)}
            {/* {renderGeneratedStubs(booksPerPage)} */}
            {renderBookTiles(books)}
        </div>
    )
}
