import { useState } from 'react';

export default function SpeedTextAreaPlain({ initialValue = '', onTextChanged, placeholder, style }) {
    const [value, setValue] = useState(initialValue);

    const handleOnChange = (event) => {
        setValue(event.target.value);
        onTextChanged(event.target.value)
    };

    return (
        <>
            <textarea value={value}
                onChange={handleOnChange}
                placeholer={placeholder}
                style={style}
                rows={5}
                columns={100}
            ></textarea>
        </>
    );
}