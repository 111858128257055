import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Indy500 extends Component {

    render() {
        const raceDirectoryName = "mid-ohio"
        const raceShortName = "Mid-Ohio"

        const timezones = [
            // { name: "Eastern Standard", abbr: "EST", utcDiff: "-0500" },
            // { name: "Central Standard", abbr: "CST", utcDiff: "-0600" },
            // { name: "Mountain Standard", abbr: "MST", utcDiff: "-0700" },
            // { name: "Pacific Standard", abbr: "PST", utcDiff: "-0800" },
            { name: "Eastern Daylight", abbr: "EDT", utcDiff: "-0400" },
            { name: "Central Daylight", abbr: "CDT", utcDiff: "-0500" },
            { name: "Mountain Daylight", abbr: "MDT", utcDiff: "-0600" },
            { name: "Pacific Daylight", abbr: "PDT", utcDiff: "-0700" },
            { name: "Brasilia", abbr: "BRT", utcDiff: "-0300" },
            { name: "Greenwich Mean", abbr: "GMT", utcDiff: "" },
            { name: "British Summer", abbr: "BST", utcDiff: "+0100" },
            // { name: "Central European", abbr: "CET", utcDiff: "+0100" },
            // { name: "Eastern European", abbr: "EET", utcDiff: "+0200" },
            // { name: "Western European", abbr: "WET", utcDiff: "" },
            { name: "Central European Summer", abbr: "CEST", utcDiff: "+0200" },
            { name: "Eastern European Summer", abbr: "EEST", utcDiff: "+0300" },
            { name: "Western European Summer", abbr: "WEST", utcDiff: "+0100" },
            { name: "Austalian Western Standard", abbr: "AWST", utcDiff: "+0800" },
            { name: "Austalian Eastern Standard", abbr: "AEST", utcDiff: "+1000" },
            // { name: "Austalian Eastern Daylight", abbr: "AEDT", utcDiff: "+1100" },
            { name: "Japan Standard", abbr: "JST", utcDiff: "+0900" },
            { name: "New Zealand Standard", abbr: "NZST", utcDiff: "+1200" },
        ]

        return (
            <div style={{ margin: "20px", display: "flex", flexDirection: "column" }}>
                <h3>{raceShortName} Track Schedule</h3>
                <div>If your timezone isn't here and you want one in your tz, drop me a line on Twitter <a target="_blank" rel="noreferrer" href="https://twitter.com/merrickspeed">@merrickspeed</a> and I'll get it up here. 👊🏼</div>

                <div className="timezone-container mt-4 mb-1">
                    {timezones.sort((a,b) => a.abbr.localeCompare(b.abbr)).map(x =>
                        <div className="timezone" key={x.abbr}>
                            <div className="timezone-abbr"><a href={`${process.env.PUBLIC_URL}/images/indycar/2022/wallpapers/2022-${raceDirectoryName}-${x.abbr.toLowerCase()}.jpg`}>{x.abbr}</a></div>
                            <div className="timezone-name">{x.name}</div>
                        </div>
                    )}
                </div>

                <h4>Adding The Wallpaper as Your Phone's Lock Screen</h4>
                <div style={{ marginBottom: "5px" }}></div>
                <div style={{ marginBottom: "5px" }}>1. Tap your timezone image above.</div>
                <div style={{ marginBottom: "5px" }}>2. Long-press image and Save to your phone.</div>
                <div style={{ marginBottom: "5px" }}>3. Go to Photos (iPhone) or Gallery (Android)</div>
                <div style={{ marginBottom: "5px" }}>4. Tap "Share" icon (iOS) or "Three Dots" (Android) while viewing photo</div>
                <div style={{ marginBottom: "5px" }}>5. Tap "Use as Wallpaper" (iOS) or "Use as/Set as" (Android)</div>
                <div style={{ fontStyle: "italic", marginTop: "15px", maxWidth: "600px" }}>If you're having problems saving the image to your phone, be sure your browser has permissions to save images (in the Privacy app on phone).</div><br />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Indy500)
