const firstBy = require('thenby');
const moment = require("moment-timezone")

export const ENVIRONMENTS = {
    PRODUCTION: "production",
    DEVELOPMENT: "development"
}

export const FEATURE_FLAGS = {
    LIVE_GAME_UPDATES: "liveGameUpdates",
    WEEKLY_SCOREBOARDS: "weeklyScoreboards",
    SET_SUMMARY_SCOREBOARD: "setSummaryScoreboard",
}

export const DEFAULT_SCORES_READY_AFTER_FINAL_GAME_START_TIME_MINUTES = 210;

export function questionsToScore(pickemGame) {
    console.log(pickemGame.questions.filter(x => x.isScoreNow));
    return pickemGame.questions.filter(x => x.isScoreNow)
}

export function score(pickemGame, entry) {

    // create a whole new object so we're not touching anything directly on the entry
    let scorecard = { ...entry, scores: [] };

    // logic gate to not score isTest and isIncomplete entries
    if (scorecard.isTest || scorecard.isIncomplete)
        return scorecard;
    else
        scorecard.isScoreable = true;

    let questionsToScore = this.questionsToScore(pickemGame);

    // match up the questionsToScore with the picks so we're only scoring picks we want
    let picksToScore = scorecard.picks;//.filter(pick => questionsToScore.map(x => x.id).includes(pick.id))

    // CURRENTLY NOT WORKING
    // ensure the number of answers we've put in the answer key equals the number of picks.
    // if (entry.picks.length < questionsToScore.filter(x => x.correctAnswers != null && x.correctAnswers.length > 0).length)
    //     throw new Error('Entry pick count does not equal the number of answer key answers (i.e. some questions are missing at least one `isCorrectAnswer` selection).');

    picksToScore.forEach((pick, idx) => {
        questionsToScore.forEach(propBet => {

            // do the base scoring all scoreboards use
            if (propBet.id === pick.id) {
                let newScoredPick = {
                    id: pick.id,
                    isCorrect: propBet.correctAnswers.some(answer => pick.selections.includes(answer)) ? true : false,
                    points: propBet.correctAnswers.some(answer => pick.selections.includes(answer)) ? questionsToScore.find(x => x.id === pick.id).value : 0
                }

                scorecard.scores.push(newScoredPick)
            }
        })

        // handle scoreboards that need extra data to render
        switch (pickemGame.scoreboardTypeId) {
            // image pick board (e.g. team logos instead of standard "green checks" and "red exes")
            // put detail on every pick on the Pick object
            case 2:
                const thisGame = pickemGame.questions.find(x => x.id === pick.id)
                pick.pickedTeamAbbr = getPickTeam(thisGame, pick)
                pick.pickedTeamLogoImageUrl = getTeamLogoImageUrlFromAbbr(thisGame.game, pick.pickedTeamAbbr)
                break;

            default:
                break;
        }
    })

    return scorecard;
}

export function scoreSportWithScoresAvailable(pickemGame, entry) {

    // create a whole new object so we're not touching anything directly on the entry
    let scorecard = { ...entry, scores: [] };

    // logic gate to not score isTest and isIncomplete entries
    if (entry.isTest || entry.isIncomplete)
        return scorecard;

    let questionsToScore = pickemGame.questions.filter(x => x.isScoreNow)

    // match up the questionsToScore with the picks so we're only scoring picks we want
    let picksToScore = entry.picks.filter(pick => questionsToScore.map(x => x.id).includes(pick.id))

    // CURRENTLY NOT WORKING
    // ensure the number of answers we've put in the answer key equals the number of picks.
    // if (entry.picks.length < questionsToScore.filter(x => x.correctAnswers != null && x.correctAnswers.length > 0).length)
    //     throw new Error('Entry pick count does not equal the number of answer key answers (i.e. some questions are missing at least one `isCorrectAnswer` selection).');

    picksToScore.forEach((pick, idx) => {
        questionsToScore.forEach(propBet => {

            if (propBet.id === pick.id) {

                // if (idx === 0) {
                //     console.log("game", pickemGame);
                //     console.log("entry", entry);
                // }

                scorecard.scores.push({
                    id: pick.id,
                    isCorrect: propBet.correctAnswers.some(answer => pick.selections.includes(answer)) ? true : false,
                    points: propBet.correctAnswers.some(answer => pick.selections.includes(answer)) ? questionsToScore.find(x => x.id === pick.id).value : 0
                })

            }
        })
    })

    return scorecard;
}

export function rankEntries(entries) {
    // assign total scores based on answers
    entries.forEach(entry => { entry.totalScore = entry.scores.filter(answer => answer.points).reduce((acc, x) => { return acc + x.points }, 0) })

    // sort the array
    entries.sort(
        firstBy("totalScore", { direction: -1 })
    );

    // clear any current place assignments
    entries.forEach(entry => entry.place = null)

    // assign a place number to the player. sometimes, there's ties. keep track of how many people are in a tie so we can increment when the next score number comes up
    let currentPlaceNumber = 1;
    for (let i = 0, entry = entries[i]; i < entries.length; i++) {
        // check how many entries have this score
        let otherEntriesWithScoreCount = entries.filter(x => x.totalScore === entry.totalScore).length;

        if (entry.isScoreable) {
            if (otherEntriesWithScoreCount > 1) {
                entry.place = "T" + currentPlaceNumber;

                // if this is the final entry of the tie, increment the current position the correct number of places
                if (entries.filter(x => x.place === entry.place).length === otherEntriesWithScoreCount)
                    currentPlaceNumber += otherEntriesWithScoreCount; // increase place number by number of entries that had this score.
            } else {
                entry.place = currentPlaceNumber;
                currentPlaceNumber += 1
            }
        } else
            entry.place = null;
    }

    return entries;
}

export function getTeamWithRanking(game, team) {
    if (game.sportName === "ncaaf" && team.division.toLowerCase() === "fbs" && team.ranking && parseInt(team.ranking) <= 25) {
        team.teamNameWithRanking = `(${team.ranking}) ${team.teamName}`
        team.teamAbbrWithRanking = `(${team.ranking}) ${team.abbr}`
    }
    else {
        team.teamNameWithRanking = team.teamName;
        team.teamAbbrWithRanking = team.abbr;
    }

    return team;
}

export function getPickTeam(game, pick) {
    return game.homeSpread < 0 ? (pick.selections[0] === 1 ? game.game.homeTeam.abbr : game.game.awayTeam.abbr) : (pick.selections[0] === 1 ? game.game.awayTeam.abbr : game.game.homeTeam.abbr)
}

export function getTeamLogoPath(game) {
    // return `/images/logos/${game.sportName.includes('ncaa') ? 'ncaa' : game.sportName}`
    return `https://superindystorage.blob.core.windows.net/images/logos/${game.sportName.includes('ncaa') ? 'ncaa' : game.sportName}`
}

export function getTeamLogoImageUrlFromAbbr(game, teamAbbr, ext = "svg") {
    let homeOrAwayTeamProperty = ""
    ext = ext.includes(".") ? ext.replace(".", "") : ext //we add the dot below

    if (game.homeTeam.abbr === teamAbbr)
        homeOrAwayTeamProperty = "homeTeam"
    else
        homeOrAwayTeamProperty = "awayTeam"

    return game[homeOrAwayTeamProperty].teamName.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/\(|\)/g, '') + "-" + game[homeOrAwayTeamProperty].nickname.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/\(|\)/g, '') + `.${ext}`
}

/**
 * Constructs an array for use in the Horizontrol Scrollbar that lists each week and the dates of that week
 * @param {string} beginningStartDate Beginning date of the season (should be a Tuesday as the football weeks run T-M). Must be formatted as "M/DD/YYYY"
 * @param {int} weekNumberToGenerateTo The week number to generate the items to
 */
export function getWeeklyScrollbarItems(beginningStartDate, currentWeekNumber) {
    // automatically set the number of weeks and the dates associated with them based on the start date of the games and the number of weeks (setNumber)
    const setCount = currentWeekNumber;
    let weekStartDate = beginningStartDate,
        weekEndDate,
        weekDayRange;
    let weeks = [];

    for (let i = 1; i <= setCount; i++) {
        weekStartDate = moment(weekStartDate, "M/DD/YYYY")
        weekEndDate = moment(weekStartDate).add(6, 'days')

        // if the end date is in the same month, the date will look like: Sep 7 - 13. In a month shift: Sep 28 - Oct 4
        weekDayRange = weekStartDate.format("M") === weekEndDate.format("M") ? `${weekStartDate.format("MMM D")} - ${weekEndDate.format("D")}` : `${weekStartDate.format("MMM D")} - ${weekEndDate.format("MMM D")}`

        weeks.push({
            weekNumber: i,
            weekDayRange
        })

        // now that we've added this week, skip 7 days to the next week for the next iteration
        weekStartDate = weekStartDate.add(7, 'days')
    }

    return weeks;
}

/**
 * Returns a boolean for whether a feature is enabled given a feature name. Very helpful to quickly toggle features in a live UI with simple true/false feature flags.
 * @param {object} topLevelObjectWithFeaturesData An object that contains a `features` property with keys for each environment supported (e.g. `production` and `development`). For each environment, keys should be simple true/false values (e.g. { liveGameUpdates: true, weeklyScoreboard: false, seasonScoreboard: false }). Each environment can set those values accordingly.
 * @param {string} environmentName Environment name as it's listed in the `features` property of the object above (should also likely be the same the the `process.env` key used for environment (e.g. `process.env.NODE_ENV`))
 * @param {string} featureName Name of the feature flag to turn on/off
 * @returns 
 */
export function isFeatureEnabled(topLevelObjectWithFeaturesData, environmentName, featureName) {
    if (topLevelObjectWithFeaturesData == null)
        throw new Error("The top-level object cannot be null.")

    if (topLevelObjectWithFeaturesData.features == null)
        throw new Error("The top-level object must have a `features` property.")

    if (topLevelObjectWithFeaturesData.features[environmentName] == null)
        throw new Error(`The environment '${environmentName}' is missing on the 'features' property.`)

    if (topLevelObjectWithFeaturesData.features[environmentName][featureName] == null)
        throw new Error(`The feature name '${featureName}' is missing on the '${environmentName}' property.`)

    return topLevelObjectWithFeaturesData.features[environmentName][featureName];
}

/**
 * Creates the CBS Game `abbr` property from other game source data in order to join datasets to each other
 * @param {string} sportName Yahoo-based sports name (e.g. ncaaf, not college-football)
 * @param {Number} startTimeUnixMs Game start time in unix ms
 * @param {string} awayTeamAbbr Away team abbreviation (e.g. DAL for Dallas)
 * @param {string} homeTeamAbbr Home team abbreviation (e.g. DAL for Dallas)
 * @returns {string} Value that looks like `NCAAF_20211204_USC@CAL` which can be joined to the CBS data
 */
export function getCbsCompositeGameAbbr(sportName, startTimeUnixMs, awayTeamAbbr, homeTeamAbbr) {
    return `${sportName}_${moment(startTimeUnixMs).tz("America/New_York").format("YYYYMMDD")}_${awayTeamAbbr}@${homeTeamAbbr}`.toUpperCase() // "abbr": "NCAAF_20211204_USC@CAL"
}

/**
 * Creates the CBS Game `abbr` property from other game source data in order to join datasets to each other
 * @param {object} game Yahoo-based `game` created in the Pickem Setup tool
 * @returns {string} Value that looks like `NCAAF_20211204_USC@CAL`
 */
export function getCbsCompositeGameAbbrFromYahooGame(game) {
    return getCbsCompositeGameAbbr(game.sportName, game.startTimeUnixMs, game.awayTeam.abbr, game.homeTeam.abbr); // "abbr": "NCAAF_20211204_USC@CAL"
}

/**
 * Gets the Last Scored text from the `match` information. Usually will render as e.g. `10 minutes ago`. But after all games have been scored, will render as `10 minutes ago (Dec 5, 2021 11:22pm)` for historical purposes.
 * @param {object} match SuperIndy pickem match
 * @returns {string} Last updated text.
 */
export function getLastScoredText(match) {
    return `${moment(match.lastScoredUnixtime * 1000).fromNow()}${match.lastScoredUnixtime && match.questions.length === match.questionsScoredCount ? ` (${moment(match.lastScoredUnixtime * 1000).tz(moment.tz.guess()).format("MMM D, YYYY h:mma z")})` : ""}`
}

/**
 * Gets the "scores ready" text. If `isAutoScoresReadyTime` is true, the function will return a date for the final game's start time plus the number of minutes desired (to approximate the game end time). If `isAutoScoresReadyTime` is false, the `scoresReadyTimeUnixMs` will be used.
 * @param {object} match SuperIndy pickem match
 * @param {int} minsAfterStartTime Number of minutes after the final start time that results will be ready
 * @returns {string} Text of the "Scores Ready" message
 */
export function getScoresReadyTimeOnly(match, minutesAfterStartTime = 0) {
    // get the unixtime we want depending on the value of isAutoScoresReadyTime
    const time = match.isAutoScoresReadyTime ? Math.max(...match.questions.map(x => x.game.startTimeUnixMs)) : match.scoresReadyTimeUnixMs

    // default to setting 3.5 hours after start time if `minutesAfterStartTime: false`
    minutesAfterStartTime = match.isAutoScoresReadyTime && minutesAfterStartTime === 0 ? DEFAULT_SCORES_READY_AFTER_FINAL_GAME_START_TIME_MINUTES : minutesAfterStartTime

    // 1. create a moment object for the time
    // 2. guess the user's timezone
    // 3. add extra minutes to the given time (if desired)
    return moment(time).tz(moment.tz.guess()).add(minutesAfterStartTime, 'minutes').format("dddd, h:mma z")
}

export function getScoresReadyText(match, minutesAfterStartTime = 0) {
    const timeText = getScoresReadyTimeOnly(match, minutesAfterStartTime);

    return `The entry window is closed for the ${match.gameName} game.<p />Results will be posted as games finish and fully complete by ${timeText}.`
}

/**
 * Rank objects of an array by a given property
 * @param {Array} arr Array of objects
 * @param {string} sortPropertyName Property on the objects to compare and sort on.
 * @param {string} placePropertyName Property of the array object to write the rank to (e.g. `lapsLedRank`)
 * @param {Number} sortOrder 1 = ascending; -1 = descending
 * @returns 
 */
 export function rank(arr, sortPropertyName, placePropertyName) {

    // clear any current place assignments
    arr.forEach(driver => driver[placePropertyName] = null)

    // assign a place number to the driver. sometimes, there's ties. keep track of how many people are in a tie so we can increment when the next score number comes up
    let currentPlaceNumber = 1;
    for (let i = 0, driver; driver = arr[i]; i++) {
        // check how many drivers have this value
        let otherDriversWithValue = arr.filter(x => x[sortPropertyName] === driver[sortPropertyName]).length;

        if (otherDriversWithValue > 1) {
            driver[placePropertyName] = "T" + currentPlaceNumber;

            // if this is the final driver of the tie, increment the current position the correct number of places
            if (arr.filter(x => x[placePropertyName] === driver[placePropertyName]).length === otherDriversWithValue)
                currentPlaceNumber += otherDriversWithValue; // increase place number by number of drivers that had this score.
        } else {
            driver[placePropertyName] = currentPlaceNumber;
            currentPlaceNumber += 1
        }
    }

    return arr
}