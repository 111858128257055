export const getRandomString = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getPickTeam(game, pick) {
    return game.homeSpread < 0 ? (pick.selections[0] === 1 ? game.game.homeTeam.abbr : game.game.awayTeam.abbr) : (pick.selections[0] === 1 ? game.game.awayTeam.abbr : game.game.homeTeam.abbr)
}

export function getTeamLogoImageUrlFromAbbr(game, teamAbbr) {
    let homeOrAwayTeamProperty = ""

    if (game.homeTeam.abbr === teamAbbr)
        homeOrAwayTeamProperty = "homeTeam"
    else
        homeOrAwayTeamProperty = "awayTeam"

    return game[homeOrAwayTeamProperty].teamName.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/\(|\)/g, '') + "-" + game[homeOrAwayTeamProperty].nickname.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/\(|\)/g, '') + ".svg"
}

export const scorePropBetPlayer = (answers, playerPicks) => {
    answers = [{

    }]

    playerPicks = [{

    }]

    // produce an 
}

export const timeToRead = (wordCount) => {
    const minWordsPerMinute = 200;
    const maxWordsPerMinute = 400;

    // if wordCount is greater than max words, give a range of time (without this, a range of time doesn't really make sense). 
    if (wordCount > maxWordsPerMinute)
        return `${Math.floor(wordCount / maxWordsPerMinute)}-${Math.floor(wordCount / minWordsPerMinute)} min read`
    else if (wordCount < minWordsPerMinute) // if less than min words per minute, give the time in seconds
        return `<1 min read`
    else // greater than min but less than max
        return `~${Math.floor(wordCount / minWordsPerMinute)} min read`;
}

/**
 * Converts an official session name into a name used to access files (e.g. Practice 1 to practice-1). Lowercases and hyphenates all spaces.
 * @param {string} sessionName Session name like Race, Practice 1, etc
 * @returns {string} Lowercase, hyphenated session name
 */
export const getLowercaseSessionNameFromSessionName = (sessionName) => {
    return `${sessionName.replace(/ /g, '-').replace('\'', '').toLowerCase()}`;
}

export const getCookieValue = (cookie, keyName) => {
    // convert cookie to query string (cheap but effective to use URLSearchParams)
    const trickCookie = cookie.replace(/;/g, '&')

    // get key/values as properties
    var values = new URLSearchParams(trickCookie)

    // check if the desired key exists. if so, return it. if not, return null
    let value = values.get(keyName)

    // if no exact match for the key exists, there's one other thing to try...
    // keys can sometimes come out of a cookie with a space at the front for some reason. find the key name by searching anywhere for it (not exact match)
    if (!value) {
        const actualKeyName = Array.from(values.keys()).find(x => x.includes(keyName))
        value = values.get(actualKeyName)
    }

    if (value)
        return value
    else
        return null
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
// example: const grouped = groupBy(pets, pet => pet.type)
//          Array.from(grouped) <-- gets entire array
//          grouped.get("Dog") <-- gets by the key

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}