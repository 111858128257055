const def = (state = { currentRace: {}, race: {} }, action) => {
    switch (action.type) {
        case "GET_CURRENT_RACE":
            return Object.assign({}, { currentRace: action.payload });
        case "GET_RACE":
            return Object.assign({}, { race: action.payload });
        default:
            return state;
    }
}

export default def;