import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import Dropdown from 'react-bootstrap/Dropdown';
// import NavItem from 'react-bootstrap/NavItem';
// import NavLink from 'react-bootstrap/NavLink';
// import Link from './Link'
import { Link } from 'react-router-dom'

import '../css/header.css'

export default class Header extends Component {
    render() {
        return (
            <div className="row superindy-header">
                <div className="col-12">
                    <Navbar bg="light" expand="lg">
                        <Navbar.Brand className="navbar-brand" href="/">
                            <span className="header-super">Super</span><span className="header-indy">Indy</span>
                            {/* <div className="header-version">alpha version</div> */}
                            {/* <div className="header-made-by-merrick-speed">made by @merrickspeed</div> */}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="navbar navbar-expand-lg navbar-light bg-light mr-auto">
                                {/* <Dropdown as={NavItem}>
                                    <Dropdown.Toggle as={NavLink} className="header-menu-item">Schedules</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link className="nav-link" href="/grid">Grid</Link>
                                        <Link className="nav-link" href="/sessions">Table</Link>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link header-menu-item" href="/grid">Schedule</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link header-menu-item" href="/detroit">Detroit</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link header-menu-item" to="/">Races</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link header-menu-item" to="/indypedia">Indypedia</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link header-menu-item" to="/games/44">Pickem</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link header-menu-item" to="/about">About</Link>
                                </li> */}
                                {/* <li className="nav-item">
                            <Link className="nav-link header-menu-item" href="/picks">Picks</Link>
                        </li> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        )
    }
}
