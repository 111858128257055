import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileImage from '../images/me-circle.png'
import MerrickSpeedTwitter from './MerrickSpeedTwitter'

export class About extends Component {
    render() {
        return (
            <div style={{ marginTop: "20px", display: "flex" }}>
                <img src={profileImage} style={{ width: "125px", height: "125px", marginRight: "10px" }} alt="@merrickspeed profile pic" />
                <div style={{ flex: "1", maxWidth: "600px" }}>

                    <p>I'm Andy -  <MerrickSpeedTwitter />. I love learning, racing, and making things. I practice those here and on Twitter. I'm not an expert in anything: not a reporter, but like writing; not a designer, but like designing; not an engineer, but like mechanical stuff.</p>

                    <p>A dude with lots of questions.</p>

                    <p>Dream job: Writing the messages on Taco Bell sauce packets.</p>

                    <p>Current life motivation: Meiji's Hello Panda chocolate-filled cookies.</p>

                    <p>Current life philosophy: Keep nothing back. And always just be - as a part of what is - and you'll be happy.</p>
                    
                    ✌🏻<br /> -Andy</div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(About)
