import React from 'react'

export default function SetCard({ card }) {
    return (
        <>
            <div style={{fontSize: '10px'}}>{card.id} of 40</div>
            <div>{card.text}</div>
        </>
    )
}
