import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getNewsItems } from '../actions/news-item-actions'
import '../css/news.css'
import moment from 'moment'
import { firstBy } from 'thenby'
import { timeToRead } from '../../src/helpers'

export class NewsListing extends Component {
    constructor(props) {
        super(props)
        console.log("NewsListing props", props);
        this.state = {

        }
    }

    // importAllImages = (r) => {
    //     let images = {};
    //     r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    //     return images;
    // }

    // images = importAllImages(require.context('./images', false, '/\.png/'));

    // <img src={images['0.png']} />


    componentDidMount() {
        this.props.getNewsItems();
    }

    renderNewsItems() {
        return <div>
            {this.props.newsItemsByDate && this.props.newsItemsByDate.sort(firstBy((a,b) => {return new Date(b.date) - new Date(a.date)})).map((dateData, idx) => {
                return <div key={idx} className="news-container">
                    {console.log(dateData)}
                    <div className="news-date-header">{moment(dateData.date, "M/D/YYYY").format("dddd, MMM D YYYY")}</div>
                    {dateData.stories.map((x, idx2) => {
                        return <div key={idx2} className="news-item">
                            <img className="news-site-logo" src={x.siteIconUrl} alt={x.siteName + " logo"} />
                            <div className="news-item-data">
                                <div className="news-title"><a href={x.url} target="_blank">{x.title}</a></div>
                                <div className="news-item-second-line">
                                    <span className="news-author">{x.byline}</span>  &middot; {timeToRead(x.wordCount)}
                                </div>
                            </div>
                        </div>
                    })
                    }
                </div>
            })
            }
        </div>
    }

    render() {
        return (
            <div>
                {this.renderNewsItems()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        newsItemsByDate: state.newsItems
    }
}

export default connect(mapStateToProps, { getNewsItems })(NewsListing)
