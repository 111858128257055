import React, { Component } from 'react'
import '../css/landing.css'
import { connect } from 'react-redux'
import { createUser } from '../actions'
import Button from 'react-bootstrap/Button'
import { fetchUserByEntryId } from '../actions'
import propbets from '../data/propbets'
import publicIp from 'public-ip'
// import Timeline from "./LandingTimeline";

// const events = [
//   { ts: "2020-10-01T14:45:00Z", text: "PRAC1" },
//   { ts: "2020-10-01T18:20:00Z", text: "QUAL" },
//   { ts: "2020-10-02T15:30:00Z", text: "RACE1" },
// //   { ts: "2017-09-16T12:22:46Z", text: "Registred" },
// //   { ts: "2017-09-16T12:21:46Z", text: "Clicked Cart" },
// //   { ts: "2017-09-16T12:20:46Z", text: "Clicked Checkout" }
// ];


class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUpdate: false,
            isNew: false,
            inputName: "",
            inputTwitter: "",
            inputEntryId: "",
            user: {},
            isInvalidEntryId: false,
            isNameMissing: false,
            cutOffUnixMs: 1632393148000,
            raceName: "Indy 500",
            cutOffTime: "Sunday, 12:45pm EDT",
            scoresReadyTime: "Sun. night after race", // don't include .
            gameId: 11,
            isShowTwitter: true
        }
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onNew = () => {
        this.setState({ isNew: true, isUpdate: false })
    }

    onUpdate = () => {
        this.setState({ isUpdate: true, isNew: false })
    }

    createEntry = async() => {
        if (this.state.inputName === "") {
            this.setState({ isNameMissing: true })
            return;
        }

        const ipAddress = await publicIp.v4();

        this.setState({ user: { ...this.state.user, firstName: this.state.inputName, twitterName: this.state.inputTwitter, entryId: this.state.inputEntryId, gameId: this.state.gameId, ipAddress: ipAddress } }, () => {
            // assuming we get back a valid entryId, navigate to picks for this user with their entryId in address bar
            this.props.createUser(this.state.user).then((user) => {
                if (user.id != null) {
                    this.props.history.push(`/picks/${this.props.user.id}`)
                }
            })
        })
    }

    getEntry = () => {
        this.props.fetchUserByEntryId(this.state.inputEntryId).then((user) => {
            if (user != null && user.id != null) {
                this.props.history.push(`/picks/${this.props.user.id}`)
            } else
                this.setState({ isInvalidEntryId: true })
        })
    }

    render() {
        return (
            <main className="page-main" style={{ fontFamily: "'PantonBlackCaps', Arial" }}>
                <div className="registration-and-rules-container">
                    <div className="entry-container">
                        {Date.now() < this.state.cutOffUnixMs &&
                            <div id="entry-options">
                                <Button className="start-new-button" onClick={() => this.onNew()}>Start New</Button>
                                <div id="already-entry">Already entered?</div>
                                <div id="update-entry-link" onClick={() => this.onUpdate()}>Update Here</div>
                                {this.state.isNew &&
                                    <div className="form landing-form">
                                        <h3>Register</h3>
                                        <label className="sr-only" htmlFor="form-name">Name</label>
                                        <input type="text" className="form-control mr-sm-2" id="form-name" placeholder="Name" value={this.state.inputName} name="inputName" onChange={this.handleInputChange} />
                                        <small id="nameHelp" className="form-text text-muted float-left mb-2">Any name for the scoreboard.</small>

                                        {this.state.isShowTwitter &&
                                            <div>
                                                <label className="sr-only" htmlFor="form-twitter">Twitter Handle</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">@</div>
                                                    </div>
                                                    <input type="text" className="form-control" id="form-twitter" placeholder="Twitter" value={this.state.inputTwitter} name="inputTwitter" onChange={this.handleInputChange} />
                                                </div>
                                                <small id="twitterHelp" className="form-text text-muted float-left">Only required if you want to win prize.</small>
                                            </div>
                                        }
                                        <br />
                                        <button type="submit" className="btn si-btn btn-success mt-1 mb-0" onClick={() => this.createEntry()}>Create</button><br />
                                        <div style={this.state.isNameMissing ? { display: "block" } : { display: "none" }} className="invalid-form-input float-left mt-0">The name field is required</div>

                                    </div>
                                }


                                {this.state.isUpdate &&
                                    <div className="landing-form">
                                        <h3>Find Entry</h3>
                                        <input type="text" id="form-entry-id" class="form-control" placeholder="Entry Id" value={this.state.inputEntryId} name="inputEntryId" onChange={this.handleInputChange} />
                                        <small id="entryIdHelp" className="form-text text-muted float-left">The 5-character code given when you entered (if you don't have this, you'll need to create a new entry)</small>
                                        <br />
                                        <button type="submit" className="btn si-btn btn-success mt-1" onClick={() => this.getEntry()}>Find</button>
                                        <div style={this.state.isInvalidEntryId ? { display: "block" } : { display: "none" }} className="invalid-form-input">Invalid entry id. Try again or create a new entry.</div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {/* <Timeline items={events} format="h:mma" headerFormat="ddd, MMM D" /> */}
                    <div className="landing-text-container">
                        {/* <div className="game-landing-header-text">The Free $100</div> */}
                        <div className="game-landing-header-text">Indy 500 Pickem</div>
                        {/* <div className="landing-text">No Freedom 100 but here's The Free $100.</div> */}
                        <div className="landing-text">It's simple! {propbets.length} questions.</div>
                        <div className="landing-text">Free. Surprise prizes to the Top 3 finishers.</div>
                        <div className="landing-text">Write your Entry Id to make changes later.</div>
                        <div className="landing-text">One entry per person, please.</div>
                        <div className="landing-text">Change answers until cut-off time.</div>
                        <div className="landing-text">Enter your Twitter so I can contact you if you win.</div>
                        <div className="landing-text">Cut-off: {this.state.cutOffTime}.</div>
                        <div className="landing-text">Scores up: {this.state.scoresReadyTime}.</div>
                        <div className="landing-subsection-text">SCORING</div>
                        <div className="landing-text">Part 1 - Questions 1-5</div>
                        <div className="landing-text">Part 1 - Scoring: Best 3 Finish Pos of Q1/Q2/Q3/Q4 minus Q5</div>
                        <div className="landing-text">Part 2 - Questions 6-22. 1 pt each.</div>
                        <div className="landing-text">Rank from each part = 50% total</div>
                        <div className="landing-text">Lowest total wins.</div>
                        <div className="landing-text">Example: Part 1 Rank P41; Part 2 P27; Total 68pts.</div>
                    </div>
                </div>
                {Date.now() > this.state.cutOffUnixMs &&
                    <div id="entry-options">
                        The entry window is closed for {this.state.raceName}.<br />Come on back {this.state.scoresReadyTime} to see results.
                    </div>
                }

                {/* <Link to="/picks" onClick={() => { console.log("click"); this.props.createUser("andy") }}>Start</Link> */}
            </main >

        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    createUser,
    fetchUserByEntryId
})(Registration)