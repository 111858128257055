import React, { Component } from 'react'
import '../../css/set.css'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { fetchSetBySetId, } from '../../actions'
import publicIp from 'public-ip'
import moment from 'moment-timezone'
import Link from '../Link'
import ReactSwipe from 'react-swipe';
import SetCard from './SetCard'

class SetLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            setId: this.props.location.pathname.split('/').pop(), // get the setId from the url so we can get the data we want
            set: { cards: [] },
            currentCardIndex: 2,
        }
    }

    componentDidMount = async () => {
        this.setState({ set: await this.props.fetchSetBySetId(this.state.setId) }, () => { })
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onNew = () => {
        this.setState({ isNew: true, isUpdate: false })
    }

    onUpdate = () => {
        this.setState({ isUpdate: true, isNew: false })
    }

    getNextDay = (day, weekday) => {
        const current = day.day()
        const days = (7 + weekday - current) % 7
        return day.clone().add(days, 'd')
    }

    setCookie = (entryId) => {
        // set a cookie so the user's entry will be saved for the weekend (through the next Friday)
        const cookieExpireDate = this.getNextDay(moment().add(1, 'd'), 5).set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString()

        // set maxAge because `expires` doesn't seem to work (always ends up as `Session` expire)
        const maxAge = moment(cookieExpireDate).diff(moment(), "s")

        // you don't set the `domain` AT ALL when setting a cookie on the `localhost` - only on a proper server
        document.cookie = `entry_id=${entryId};path=/;max-age=${maxAge};SameSite=lax;${process.env?.NODE_ENV?.toLowerCase() === 'production' ? 'domain=superindy.com;' : ""}`
    }

    createEntry = async () => {
        if (this.state.inputName === "") {
            this.setState({ isNameMissing: true })
            return;
        }

        const ipAddress = await publicIp.v4();

        this.setState({ user: { ...this.state.user, firstName: this.state.inputName, teamName: this.state.teamName, twitterName: this.state.inputTwitter, entryId: this.state.inputEntryId, gameId: this.state.gameId, ipAddress: ipAddress, isTest: false, place: null, totalScore: 0, scores: [] } }, () => {
            // assuming we get back a valid entryId, navigate to picks for this user with their entryId in address bar
            this.props.createUser(this.state.user).then((user) => {
                if (user.id != null) {
                    this.setCookie(user.id)

                    this.props.history.push(`/picks/${this.props.user.id}`, { gameId: this.state.gameId })
                }
            })
        })
    }

    getEntry = () => {
        this.props.fetchUserByEntryId(this.state.inputEntryId).then((user) => {
            // check if we're past the game cutoff. if so, re-direct to scoreboard
            if (this.state.game.cutOffUnixMs < moment().unix() * 1000) {
                this.props.history.push(`/scores/${this.state.gameId}`)
                return;
            }

            if (user != null && user.id != null) {
                this.props.history.push(`/picks/${this.props.user.id}`, { gameId: this.state.gameId })
            } else
                this.setState({ isInvalidEntryId: true })
        })
    }

    renderAvailableGames = () => {

    }

    renderCards = (cards) => {
        return cards.map(card => <div key={card.id}><SetCard card={card} /></div>)
    }

    renderSet = (set) => {
        switch (set.gameTypeId) {
            case 5:
                return this.renderGameId5(set);
            case 6:
                return this.renderGameId6(set);
            default:
                return <div>No Game.</div>
        }
    }

    renderGameId5 = (set) => {
        let reactSwipeEl;
        let styles = {
            height: '400px',
            width: '100%',
            textAlign: 'center'
        };

        let buttonStyle = {

        }

        return <main className="center-content" style={styles} >
            {/* {this.state.set.cards && <div>{this.state.currentCardIndex + 1} of {this.state.set.cards.length}</div>} */}
            {console.log(this.state.set)}
            {
                set && set.cards &&
                <div style={{ margin: '10px', padding: '10px', border: "1px solid gray", verticalAlign: 'center' }}>
                    <ReactSwipe
                        childCount={set.cards.length}
                        className="carousel"
                        swipeOptions={{ continuous: false }}
                        ref={el => (reactSwipeEl = el)}
                        callback={(index, elem) => { console.log(elem) }}
                    >
                        {/* <div>PANE 1</div>
            <div>PANE 2</div>
            <div>PANE 3</div> */}
                        {
                            set.cards && this.renderCards(set.cards)
                        }
                    </ReactSwipe>
                </div>

            }
        </main >
    }

    renderGameId6 = (set) => {
        return <div>{set.gameName}</div>
    }

    render() {
        return (
            this.renderSet(this.state.set)

        )
    }
}

const mapStateToProps = (state) => {
    return {
        set: state.set
    }
}

export default connect(mapStateToProps, {
    fetchSetBySetId,
})(SetLanding)