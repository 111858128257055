const def = (state = { picks: [] }, action) => {
    switch (action.type) {
        case "FETCH_USER_BY_ENTRY_ID":
            return Object.assign({}, action.payload)
        case "GET_GAME_ENTRY_COUNT":
            return Object.assign({}, action.payload);
        case "CREATE_USER":

            return action.payload;
        case "PROPBET_SELECTED":
        case "PROPBET_UPDATED":
            return action.payload;
        default:
            return state;
    }
}

export default def;