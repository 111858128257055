import React, { Component } from 'react'
import '../../css/set.css'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { fetchGameByGameId, } from '../../actions'
import publicIp from 'public-ip'
import moment from 'moment-timezone'
import Link from '../Link'


class SetLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            setId: this.props.location.pathname.split('/').pop(), // get the setId from the url so we can get the data we want
            game: { cards: [] }
        }
    }

    componentDidMount = async () => {

    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onNew = () => {
        this.setState({ isNew: true, isUpdate: false })
    }

    onUpdate = () => {
        this.setState({ isUpdate: true, isNew: false })
    }

    getNextDay = (day, weekday) => {
        const current = day.day()
        const days = (7 + weekday - current) % 7
        return day.clone().add(days, 'd')
    }

    setCookie = (entryId) => {
        // set a cookie so the user's entry will be saved for the weekend (through the next Friday)
        const cookieExpireDate = this.getNextDay(moment().add(1, 'd'), 5).set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString()

        // set maxAge because `expires` doesn't seem to work (always ends up as `Session` expire)
        const maxAge = moment(cookieExpireDate).diff(moment(), "s")

        // you don't set the `domain` AT ALL when setting a cookie on the `localhost` - only on a proper server
        document.cookie = `entry_id=${entryId};path=/;max-age=${maxAge};SameSite=lax;${process.env?.NODE_ENV?.toLowerCase() === 'production' ? 'domain=superindy.com;' : ""}`
    }

    createEntry = async () => {
        if (this.state.inputName === "") {
            this.setState({ isNameMissing: true })
            return;
        }

        const ipAddress = await publicIp.v4();

        this.setState({ user: { ...this.state.user, firstName: this.state.inputName, teamName: this.state.teamName, twitterName: this.state.inputTwitter, entryId: this.state.inputEntryId, gameId: this.state.gameId, ipAddress: ipAddress, isTest: false, place: null, totalScore: 0, scores: [] } }, () => {
            // assuming we get back a valid entryId, navigate to picks for this user with their entryId in address bar
            this.props.createUser(this.state.user).then((user) => {
                if (user.id != null) {
                    this.setCookie(user.id)

                    this.props.history.push(`/picks/${this.props.user.id}`, { gameId: this.state.gameId })
                }
            })
        })
    }

    startSet = (setId) => {
        this.props.history.push(`/sets/${setId}`, { setId })
    }

    renderAvailableGames = () => {

    }

    render() {
        return (
            <main className="center-content">
                <div>Set Landing {this.state.setId}</div>

                <button type="submit" className="btn si-btn btn-success mt-1 mb-0" onClick={() => this.startSet(this.state.setId)}>Start</button>
            </main >

        )
    }
}

const mapStateToProps = (state) => {
    return {
        availableGames: state.game.availableGames
    }
}

export default connect(mapStateToProps, {
    fetchGameByGameId,
})(SetLanding)