const def = (state = { questions: [] }, action) => {
    switch (action.type) {
        case "FETCH_GAME":
            return Object.assign({}, { currentGame: action.payload});
        case "CURRENTLY_AVAILABLE_GAMES":
            console.log(6);
            return Object.assign([], { availableGames: action.payload});
        default:
            return state;
    }
}

export default def;