import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ExistingEntryModal({ username }) {
    return (
        <Modal.Dialog>
            <Modal.Header closeButton>
                <Modal.Title>Existing Entry Selection</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>There's an existing entry in this game for {username}. Would you like to edit the entry or start a new one (e.g. a parent adding a new entry for their kid)?</div>
                <Button variant="secondary float-left">Edit Existing</Button>
                <Button variant="secondary float-right">Start New</Button>
            </Modal.Body>

            {/* <Modal.Footer>
        
      </Modal.Footer> */}
        </Modal.Dialog>
    );
}

export default ExistingEntryModal;