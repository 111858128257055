import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { getCurrentRace, getRace } from '../actions'
import { getLowercaseSessionNameFromSessionName } from '../helpers'

import '../css/race-weekend.css'



export class IndyCar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: {},
            currentRace: {}
        }
    }

    componentDidMount = async () => {
        // first, get the current race
        this.setState({ currentRace: await this.props.getCurrentRace() }, async () => {
            // the get the data for what whatever race this references
            this.setState({ data: await this.props.getRace(this.state.currentRace.year, this.state.currentRace.raceNumber, this.state.currentRace.raceDirectoryName) })
        })
    }

    renderSessions(sessions) {
        // filters out the Round 1/Round 2/Fast sessions in favor of the generic "Qualifying" session manually added in the Indy Speed race setup
        return sessions && sessions.filter(x => !x.sessionName.includes("Qualifications") && !x.sessionName.includes("Fast")).map(session => <tr key={session.seriesSessionId}>
            <td className="race-weekend session name">{session.sessionName}</td>
            <td className="race-weekend session date">{moment(session.sessionStartDate).format("ddd, MMM D")}</td>
            <td className="race-weekend session times">{moment(session.sessionStartDate).format("h:mm")} - {moment(session.sessionEndDate).format("h:mma")}</td>
            
            ```````````````````{(session.data.collectionTimelineUrl && moment().isAfter(moment(session.sessionStartDate)) ? <td className="race-weekend session session-asset-icon"><a href={session.data.collectionTimelineUrl} rel="noreferrer noopener" target="_blank"><i className="fab fa-twitter"></i></a></td> : <td className="race-weekend session session-asset-icon">—</td>)}```````````````````
            
            {(session.data.isResultsPostedTimes && moment().isAfter(moment(session.sessionEndDate, session.timezoneString)) ? <td className="race-weekend session session-asset-icon"><Link to={`/results/${this.state.currentRace.year}/${this.state.currentRace.raceDirectoryName}/${getLowercaseSessionNameFromSessionName(session.sessionName)}/times`}><i className="fas fa-th-list"></i></Link></td> : <td className="race-weekend session session-asset-icon">—</td>)}
            
            {(session.data.isResultsPostedLaps && moment().isAfter(moment.tz(session.sessionEndDate, session.timezoneString)) ? <td className="race-weekend session session-asset-icon"><Link to={`/results/${this.state.currentRace.year}/${this.state.currentRace.raceDirectoryName}/${getLowercaseSessionNameFromSessionName(session.sessionName)}/laps`}><i className="fas fa-th-list"></i></Link></td> : <td className="race-weekend session session-asset-icon">—</td>)}
            <td></td>
        </tr>)

    }

    render() {
        return (
            <div id="race-weekend">
                <div id="race-weekend-header">
                    <img src={this.state.data.raceLogoImageUrl} alt={`Race logo`} className="race-weekend race-logo mr-2" />
                    <div className="race-weekend race-info-box">
                        <h2 className="race-weekend race-name">{this.state.data.raceShortName}</h2>
                        <h6 className="race-weekend race-name-official">{this.state.data.officialRaceName}</h6>
                    </div>
                </div>
                <div className="race-weekend race-items mt-2">
                    {this.state.data.liveriesSpecialImageUrl ? <Link to="/special-liveries"> <i className="fas fa-sparkles race-weekend-icon"></i>Special Liveries</Link> : <div><i className="fas fa-sparkles race-weekend-icon"></i> Special Liveries (Spotter Guide Not Released Yet)</div>}
                    <Link to="/wallpaper"> <i className="fas fa-calendar-alt race-weekend-icon"></i>Track Schedule (Phone Wallpaper)</Link>
                    <a href={this.state.data.weekendTimelineCollectionUrl} rel="noreferrer noopener" target="_blank"> <i className="fab fa-twitter race-weekend-icon"></i>Race Weekend Tweet Collection</a>
                    <a href={`/races/${this.state.currentRace.year}/${this.state.currentRace.raceDirectoryName}/radios`}> <i className="fas fa-walkie-talkie race-weekend-icon"></i>Radios</a>
                </div>
                <div className="race-weekend sessions mt-3">
                    <h4>Sessions</h4>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className="race-weekend session session-asset-icon">Tweets</th>
                                <th className="race-weekend session session-asset-icon">Results</th>
                                <th className="race-weekend session session-asset-icon">Laps</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderSessions(this.state.data.sessions)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    getCurrentRace,
    getRace
}

export default connect(mapStateToProps, mapDispatchToProps)(IndyCar)