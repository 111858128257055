import React, { useState, useEffect, useRef } from 'react'
import styles from './PayKonnectIntro.module.css'
import PortfolioItem from './PortfolioItem'
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/dist/plugins/captions";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/captions/captions.css";

export default function PayKonnectIntro() {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [slides, setSlides] = useState([])
  const captionsRef = useRef(null);

  useEffect(() => {
    if (lightboxIsOpen) {

    }
  }, [lightboxIsOpen])

  const AZURE_STORAGE_IMAGES_BASE_URL = "https://superindystorage.blob.core.windows.net/images/portfolio"
  const AZURE_STORAGE_FILES_BASE_URL = "https://superindystorage.blob.core.windows.net/files"

  const items = [
    {
      number: "001", title: "Pickem Game", introText: "For the past 3 years, about 15-20 of my family and friends play this Pickem game each week during football season.", skills: ["React", "Redux", "Azure App Service", "Azure Repos", "Azure Pipelines", "Azure Cosmos (MongoDb)"], slides: [
        { src: 'pickem/10.jpg', title: 'Sign Up Screen', caption: "The landing page for the Pickem game." },
        { src: 'pickem/20.jpg', title: "Sign Up After Cut-off Time", caption: "Sign up automatically closes at the kickoff time of the first game." },
        { src: 'pickem/30.jpg', title: "Name and Team Name", caption: "Real name and joke name to get a laugh from other players." },
        { src: 'pickem/40.jpg', title: "Update Entry", caption: "We have some people who won't play with single-sign on logins because of privacy concerns. To include everyone, users instead get an Entry Id and can change their week's picks up until the cutoff time." },
        { src: 'pickem/50.jpg', title: "Picks View", caption: "Each week, I select 16-20 games for everyone to pick. We word the questions this way because spreads were too hard for about 50% of the players to understand." },
        { src: 'pickem/60.jpg', title: "Picks View (Progress)", caption: "Users can see the games they picked and can go back and change any games they want until the cutoff." },
        { src: 'pickem/70.jpg', title: "Picks View (Mobile)", caption: "There's a mobile layout since 75% of people enter picks on their phones." },
        { src: 'pickem/80.jpg', title: "Finished View", caption: "When a user finishes, they get a page indicating they're done and a link to the scoreboard for the week." },
        { src: 'pickem/90.jpg', title: "Scoreboard (Pre-Cutoff)", caption: "Players can see who else is playing that week by checking the scoreboard." },
        { src: 'pickem/100.jpg', title: "Scoreboard (Post-Cutoff)", caption: "As games finish, player picks get automatically scored and the scoreboard updates. This scoreboard is the end of last year's Week 9." },
        { src: 'pickem/110.jpg', title: "Player Pick Detail", caption: "Clicking on any of the team logos shows detail about that game, including final score." },
        { src: 'pickem/120.jpg', title: "Player Pick List", caption: "Clicking on a user's name displays all their picks for the week along with the game schedules/broadcast info for easy reference." },
        { src: 'pickem/130.jpg', title: "Scoreboard (Zoomed View)", caption: "Winners are highlighted green. Rare to have a 4-way tie for first.\nCorrect picks get a color logo.\nWrong picks stay grayscale and get an 'X' next to them." },
        { src: 'pickem/140.jpg', title: "Season Standings", caption: "Simple standings to see who's winning for the season." },
      ]
    },
    {
      number: "002", title: "Documentation", introText: "The better the documentation, the better others learn, do, and feel. It helps save lots of time for customers, teammates, and yourself. I like writing quality docs. These PDFs are generated from Markdown. I've used the React Basics as a guide to help me teach React to others.", skills: ["Markdown", "Documentation", "Teaching"],
      files: [
        { src: 'pdfs/azure-create-deployment-slot.pdf', title: 'Azure Add Deploy Slot' },
        { src: 'pdfs/azure-app-service-certs.pdf', title: 'Azure SSL Certs' },
        { src: 'pdfs/react-basics.pdf', title: 'React Basics' },
        { src: 'pdfs/puppeteer-snippets.pdf', title: 'Puppeteer Snippets' }
      ]
    },
    { number: "003", title: "Design and Reports", introText: "", skills: ["HTML", "CSS", "PostgreSQL", "Puppeteer", "Design", "Reports"] },
    { number: "004", title: "IndyCar Driver Radios", introText: "", skills: ["Angular", "Problem Solving"] },
    { number: "005", title: "ASP.NET with React", introText: "", skills: ["ASP.NET", "React", "Design"] },
  ]

  function renderPortfolioItems(items) {
    return items.map(item => <PortfolioItem key={item.number} number={item.number}
      title={item.title} introText={item.introText} skills={item.skills} slides={item.slides} files={prepareFiles(item.files)} onLightboxOpen={handleLightboxOpen}
    />)
  }

  function prepareSlides(slides) {
    return slides.map(x => ({ src: `${AZURE_STORAGE_IMAGES_BASE_URL}/${x.src}`, description: x.caption, title: x.title }))
  }

  function prepareFiles(files) {
    return files?.map(x => ({ src: `${AZURE_STORAGE_FILES_BASE_URL}/${x.src}`, title: x.title}))
  }

  function handleLightboxOpen(slides) {
    setSlides(prepareSlides(slides))
    setLightboxIsOpen(true)
  }

  return (
    <div>
      <h1 className={styles.h1}>ANDY MERRICK</h1>
      <h3 className={styles.title}>SOFTWARE ENGINEER</h3>

      <div className={`${styles.portfolioContainer} mt-2`}>
        {renderPortfolioItems(items)}
      </div>

      <Lightbox
        plugins={[Captions]}
        captions={{ ref: captionsRef, descriptionTextAlign: 'center' }}
        on={{
          click: () => {
            (captionsRef.current?.visible
              ? captionsRef.current?.hide
              : captionsRef.current?.show)?.();
          }
        }}
        open={lightboxIsOpen}
        close={() => setLightboxIsOpen(false)}
        slides={slides}
      />
    </div>
  )
}
