import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({
    children,
    disabled,
    onClick,
    className
}) {
    return (
        <div
            disabled={disabled}
            onClick={onClick}
            className={className}
        >
            {children}
        </div>
    );
}

export function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

    return (
        <Arrow className="scrollbar-arrow" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <i className="fas fa-chevron-left"></i>
        </Arrow>
    );
}

export function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

    return (
        <Arrow className="scrollbar-arrow" disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <i className="fas fa-chevron-right"></i>
        </Arrow>
    );
}
