import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCurrentRace, getRace } from '../actions'

import '../css/special-liveries.css'

export class LiveriesSpecial extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: {},
            currentRace: {}
        }
    }

    componentDidMount = async () => {
        // first, get the current race
        this.setState({ currentRace: await this.props.getCurrentRace() }, async () => {
            // the get the data for what whatever race this references
            this.setState({ data: await this.props.getRace(this.state.currentRace.year, this.state.currentRace.raceNumber, this.state.currentRace.raceDirectoryName) })
        })
    }

    render() {
        return (
            <div>
                <img src={this.state.data.liveriesSpecialImageUrl} alt={`Special liveries`} id="special-liveries-box" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    getCurrentRace,
    getRace
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveriesSpecial)