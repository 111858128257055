import localApi from '../apis/local'

export const FETCH_NEWS_ITEMS = "FETCH_NEWS_ITEMS";

export const getNewsItems = (newsItemQuery) => async dispatch => {
    console.log("niq", newsItemQuery);
    const query = { ...newsItemQuery }

    // get the news items
    const newsItems = await localApi.get(`/news-items`)
    
    // dispatch the action
    dispatch({
        type: FETCH_NEWS_ITEMS,
        payload: newsItems.data
    })
}