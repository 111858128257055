import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function PickemScrollbarCard({
    onClick,
    selected,
    title,
    subtitle,
    itemId
}) {    
    const visibility = React.useContext(VisibilityContext);
    
    // const visible = visibility.isItemVisible(itemId);

    return (
        <div
            className="scrollbar-container"
            tabIndex={0}
            selected={selected}
            style={{ fontWeight: selected ? "bold" : "inherit" }}
            onClick={() => onClick() }
        >
            <div className="scrollbar-card">
                <div className="scrollbar-card-title">{title}</div>
                <div className="scrollbar-card-subtitle">{subtitle}</div>
                <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
                <div>selected: {JSON.stringify(!!selected)}</div>
            </div>
        </div>
    );
}