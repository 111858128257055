import React, { Component } from 'react'
import '../css/landing.css'
import { connect } from 'react-redux'
import { createUser } from '../actions'
import Button from 'react-bootstrap/Button'
import { fetchUserByEntryId, fetchGameByGameId, getGameEntryCountByGameId, getCurrentlyAvailableGames } from '../actions'
import { getScoresReadyTimeOnly, getScoresReadyText } from '../scripts/pickem-helpers'
import { getCookieValue, getRandomString } from '../helpers'
import publicIp from 'public-ip'
import moment from 'moment-timezone'
import Link from './Link'
import ExistingEntryModal from './Registration/ExistingEntryModal'

// Setup moment timezone abbreviations
const timezoneString = "America/New_York";
moment.tz.setDefault(timezoneString)
const timezoneAbbrs = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
    UTC: 'Universal Time',
    BST: 'British Summer Time'
};

moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return timezoneAbbrs[abbr] || abbr;
};



class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUpdate: false,
            isNew: false,
            inputName: "",
            teamName: "",
            inputTwitter: "",
            inputEntryId: "",
            user: {},
            isInvalidEntryId: false,
            isNameMissing: false,
            gameId: this.props.location.pathname.split('/').pop(), // get the gameId from the url so we can get the data we want
            game: { questions: [] }
        }
    }

    componentDidMount = async () => {
        // if we don't specify an actual game, pull the valid games available for play right now.
        if (this.state.gameId !== "football") {
            // look at the cookie to see if the user has an entry this week
            const entryId = getCookieValue(document.cookie, "entry_id")

            // load the game info
            const game = await this.props.fetchGameByGameId(this.state.gameId);
            const gameEntryCount = await this.props.getGameEntryCountByGameId(this.state.gameId);

            this.setState({ game, gameEntryCount }, () => {
                // if the cookie has an id, display a pop-up asking if they want to continue with this entry or start a new one
                if (entryId) {
                    this.renderExistingEntryModal("Andy")
                    // go straight to the entry (or board if deadline has passed)
                    // this.setState({ inputEntryId: entryId }, () => {
                    //     this.getEntry()
                    // })
                }
            })

        }
        else {
            if (this.state.gameId >= 35)
                await this.props.getCurrentlyAvailableGames()
        }
    }

    renderExistingEntryModal(username) {
        ExistingEntryModal(username)
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onNew = () => {
        this.setState({ isNew: true, isUpdate: false })
    }

    onNewAnonymous = () => {
        this.setState({ isNew: true, isUpdate: false }, this.createEntry)
    }

    onUpdate = () => {
        this.setState({ isUpdate: true, isNew: false })
    }

    getNextDay = (day, weekday) => {
        const current = day.day()
        const days = (7 + weekday - current) % 7
        return day.clone().add(days, 'd')
    }

    setCookie = (entryId) => {
        // set a cookie so the user's entry will be saved for the weekend (through the next Friday)
        const cookieExpireDate = this.getNextDay(moment().add(1, 'd'), 5).set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString()

        // set maxAge because `expires` doesn't seem to work (always ends up as `Session` expire)
        const maxAge = moment(cookieExpireDate).diff(moment(), "s")

        // you don't set the `domain` AT ALL when setting a cookie on the `localhost` - only on a proper server
        document.cookie = `entry_id=${entryId};path=/;max-age=${maxAge};SameSite=lax;${process.env?.NODE_ENV?.toLowerCase() === 'production' ? 'domain=superindy.com;' : ""}`
    }

    createEntry = async () => {
        // some entries are allowed to have anonymous entries, some require a name. check.
        let anonymousUser;
        switch (this.state.game.gameTypeId) {
            case 6:
                anonymousUser = getRandomString(5)
                break;
            default:
                if (this.state.inputName === "") {
                    this.setState({ isNameMissing: true })
                    return;
                }
        }

        const ipAddress = await publicIp.v4();

        this.setState({ user: { ...this.state.user, firstName: anonymousUser ?? this.state.inputName, teamName: this.state.teamName, twitterName: this.state.inputTwitter, entryId: this.state.inputEntryId, gameId: this.state.gameId, ipAddress: ipAddress, isTest: false, place: null, totalScore: 0, scores: [] } }, () => {
            // assuming we get back a valid entryId, navigate to picks for this user with their entryId in address bar
            this.props.createUser(this.state.user).then((user) => {
                if (user.id != null) {
                    this.setCookie(user.id)

                    this.props.history.push(`/picks/${this.props.user.id}`, { gameId: this.state.gameId })
                }
            })
        })
    }

    getEntry = () => {
        this.props.fetchUserByEntryId(this.state.inputEntryId).then((user) => {
            // check if we're past the game cutoff. if so, re-direct to scoreboard
            if (this.state.game.cutOffUnixMs < moment().unix() * 1000) {
                this.props.history.push(`/scores/${this.state.gameId}`)
                return;
            }

            if (user != null && user.id != null) {
                this.props.history.push(`/picks/${this.props.user.id}`, { gameId: this.state.gameId })
            } else
                this.setState({ isInvalidEntryId: true })
        })
    }

    renderAvailableGames = () => {

    }

    renderPickemInstructions = () => {
        return <main className="page-main" style={{ fontFamily: "'PantonBlackCaps', Arial" }}>
            <h1 className="game-title">{this.state.game.gameName} Pickem</h1>
            {Date.now() > this.state.game.cutOffUnixMs &&
                <span>
                    <Link className="scoreboard-button" href={"/scores/" + this.state.gameId}>Current Scoreboard<div className="scoreboard-button-scored-text">({this.state.game.questionsScoredCount ?? 0} scored of {this.state.game.questions.length} total)</div></Link>

                    <div id="scores-ready-text" dangerouslySetInnerHTML={{ __html: getScoresReadyText(this.state.game).toUpperCase() }}>
                    </div>
                </span>
            }
            <div className="registration-and-rules-container">
                <div className="entry-container">
                    {Date.now() < this.state.game.cutOffUnixMs &&
                        <div id="entry-options">
                            <Button className="start-new-button" onClick={() => this.onNew()}>Start New</Button>
                            <div id="already-entry">Already entered?</div>
                            <div id="update-entry-link" onClick={() => this.onUpdate()}>Update Here</div>
                            {this.state.isNew &&
                                <div className="form landing-form">
                                    <h3>Register</h3>
                                    <label className="sr-only" htmlFor="form-name">Name</label>
                                    <input type="text" className="form-control mr-sm-2" id="form-name" placeholder="Name" value={this.state.inputName} name="inputName" onChange={this.handleInputChange} />
                                    <small id="nameHelp" className="form-text text-muted float-left mb-2">Any name for the scoreboard.</small>

                                    <label className="sr-only" htmlFor="team-name">Team Name</label>
                                    <input type="text" className="form-control mr-sm-2" id="team-name" placeholder="Team Name" value={this.state.teamName} name="teamName" onChange={this.handleInputChange} />
                                    <small id="team-name-help" className="form-text text-muted float-left mb-2">(Optional) Team Nickname.</small>

                                    {this.state.game.isShowTwitter &&
                                        <div>
                                            <label className="sr-only" htmlFor="form-twitter">Twitter Handle</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">@</div>
                                                </div>
                                                <input type="text" className="form-control" id="form-twitter" placeholder="Twitter" value={this.state.inputTwitter} name="inputTwitter" onChange={this.handleInputChange} />
                                            </div>
                                            <small id="twitterHelp" className="form-text text-muted float-left">Not required (used on scoreboard for bragging rights)</small>
                                        </div>
                                    }
                                    <br />
                                    <button type="submit" className="btn si-btn btn-success mt-1 mb-0" onClick={() => this.createEntry()}>Create</button><br />
                                    <div style={this.state.isNameMissing ? { display: "block" } : { display: "none" }} className="invalid-form-input float-left mt-0">The name field is required</div>

                                </div>
                            }


                            {this.state.isUpdate &&
                                <div className="landing-form">
                                    <h3>Find Entry</h3>
                                    <input type="text" id="form-entry-id" className="form-control" placeholder="Entry Id" value={this.state.inputEntryId} name="inputEntryId" onChange={this.handleInputChange} />
                                    <small id="entryIdHelp" className="form-text text-muted float-left">The 5-character code given when you entered (if you don't have this, you'll need to create a new entry)</small>
                                    <br />
                                    <button type="submit" className="btn btn-success mt-1" onClick={() => this.getEntry()}>Find</button>
                                    <div style={this.state.isInvalidEntryId ? { display: "block" } : { display: "none" }} className="invalid-form-input">Invalid entry id. Try again or create a new entry.</div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {Date.now() < this.state.game.cutOffUnixMs &&
                    <div className="landing-text-container">
                        {/* <div className="game-landing-header-text">The Free $100</div> */}
                        {/* <div className="landing-text">No Freedom 100 but here's The Free $100.</div> */}
                        <div className="landing-text">It's simple! {this.state.game.questions.length} questions.</div>
                        <div className="landing-text">Number of <Link href={"/scores/" + this.state.gameId}>current entries</Link>: {this.state.gameEntryCount}</div>
                        {/* <div className="landing-text">Free. $75 1st, $15 2nd, $10 3rd.</div> */}
                        <div className="landing-text">Write down your Entry Id to make changes later</div>
                        <div className="landing-text">Change answers until the cut-off time.</div>
                        {/* <div className="landing-text">Enter your Twitter so I can contact you if you win.</div> */}
                        <div className="landing-text">Cut-off: {moment(this.state.game.cutOffUnixMs).tz(moment.tz.guess()).format("dddd, h:mma z")}.</div>
                        <div className="landing-text">Scores final: {getScoresReadyTimeOnly(this.state.game)}.</div>
                        <div className="landing-subsection-text">SCORING</div>
                        {/* <div className="landing-text">Each question varies in points.</div> */}
                        <div className="landing-text">Each game is worth 1 point.</div>
                        <div className="landing-text">{this.state.game.scoresReadyText}</div>
                        {/* <div className="landing-text">Each game worth 1pt each.</div>
                    <div className="landing-text">Each game scored immediately after finish.</div> */}
                    </div>
                }
            </div>
        </main>
    }

    renderBasicQuestionsInstructions = () => {
        return <main className="page-main" style={{ fontFamily: "'PantonBlackCaps', Arial" }}>
            <h1 className="game-title">{this.state.game.gameName}</h1>
            <div className="registration-instructions">{ this.state.game.registrationIntroText }</div>
            <Button className="start-new-button" onClick={() => this.onNewAnonymous()}>Start</Button>
            {/* <div id="already-entry">Want to change answers?</div>
            <div id="update-entry-link" onClick={() => this.onUpdate()}>Update Here</div> */}
        </main>
    }

    renderForm = (gameTypeId) => {
        if (gameTypeId == null)
            return <div></div>

        switch (gameTypeId) {
            case 6:
                return this.renderBasicQuestionsInstructions();
            default:
                return this.renderPickemInstructions()
        }
    }

    render() {
        return (
            this.state.gameId === "football" ?
                <main className="page-main" style={{ fontFamily: "'PantonBlackCaps', Arial", minHeight: "0px" }}>
                    <div>SELECT FROM THESE ACTIVE GAMES:</div>
                    {this.renderAvailableGames()}
                </main>
                :
                this.state.gameId >= 35 && this.renderForm(this.state.game.gameTypeId)
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        availableGames: state.game.availableGames
    }
}

export default connect(mapStateToProps, {
    createUser,
    fetchUserByEntryId,
    fetchGameByGameId,
    getGameEntryCountByGameId,
    getCurrentlyAvailableGames
})(Registration)