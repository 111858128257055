module.exports = [
    {
        id: 129,
        layoutId: 3,
        text: "Who will have the best finishing position from drivers in the first three rows?",
        excludePickFromIds: [],
        options: [{ id: 1, text: "1st Dixon" }, { id: 2, text: "2nd Herta" }, { id: 3, text: "3rd VeeKay" },
        { id: 4, text: "4th Carpenter" }, { id: 5, text: "5th Kanaan" }, { id: 6, text: "6th Palou" },
        { id: 7, text: "7th Hunter-Reay" }, { id: 8, text: "8th Castroneves" }, { id: 9, text: "9th Ericsson" }
        ],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 130,
        layoutId: 3,
        text: "Who will have the best finishing position from drivers in Rows 4-7?",
        excludePickFromIds: [],
        options: [{ id: 10, text: "10th Rossi" }, { id: 11, text: "11th Jones" }, { id: 12, text: "12th O'Ward" },
        { id: 13, text: "13th Fittipaldi" }, { id: 14, text: "14th Rosenqvist" }, { id: 15, text: "15th Sato" },
        { id: 16, text: "16th Hinchcliffe" }, { id: 17, text: "17th McLaughlin" }, { id: 18, text: "18th Rahal" },
        { id: 19, text: "19th Daly" }, { id: 20, text: "20th Harvey" }, { id: 21, text: "21st Newgarden" }
        ],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 131,
        layoutId: 3,
        text: "Who will have the best finishing position from the last four rows?",
        excludePickFromIds: [],
        options: [{ id: 22, text: "22nd Hildebrand" }, { id: 23, text: "23rd Ferrucci" }, { id: 24, text: "24th Montoya" },
        { id: 25, text: "25th Andretti" }, { id: 26, text: "26th Pagenaud" }, { id: 27, text: "27th Bourdais" },
        { id: 28, text: "28th Wilson" }, { id: 29, text: "29th Chilton" }, { id: 30, text: "30th Kellett" },
        { id: 31, text: "31st Karam" }, { id: 32, text: "32nd Power" }, { id: 33, text: "33rd De Silvestro" }
        ],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 132,
        layoutId: 3,
        text: "Pick one additional driver from anywhere in the field (that you haven't yet selected) who will have the best finishing position.",
        excludePickFromIds: [129, 130, 131],
        options: [
            { id: 1, text: "1st Dixon" }, { id: 2, text: "2nd Herta" }, { id: 3, text: "3rd VeeKay" },
            { id: 4, text: "4th Carpenter" }, { id: 5, text: "5th Kanaan" }, { id: 6, text: "6th Palou" },
            { id: 7, text: "7th Hunter-Reay" }, { id: 8, text: "8th Castroneves" }, { id: 9, text: "9th Ericsson" },
            { id: 10, text: "10th Rossi" }, { id: 11, text: "11th Jones" }, { id: 12, text: "12th O'Ward" },
            { id: 13, text: "13th Fittipaldi" }, { id: 14, text: "14th Rosenqvist" }, { id: 15, text: "15th Sato" },
            { id: 16, text: "16th Hinchcliffe" }, { id: 17, text: "17th McLaughlin" }, { id: 18, text: "18th Rahal" },
            { id: 19, text: "19th Daly" }, { id: 20, text: "20th Harvey" }, { id: 21, text: "21st Newgarden" },
            { id: 22, text: "22nd Hildebrand" }, { id: 23, text: "23rd Ferrucci" }, { id: 24, text: "24th Montoya" },
            { id: 25, text: "25th Andretti" }, { id: 26, text: "26th Pagenaud" }, { id: 27, text: "27th Bourdais" },
            { id: 28, text: "28th Wilson" }, { id: 29, text: "29th Chilton" }, { id: 30, text: "30th Kellett" },
            { id: 31, text: "31st Karam" }, { id: 32, text: "32nd Power" }, { id: 33, text: "33rd De Silvestro" }
        ],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 133,
        layoutId: 3,
        text: "Pick one final driver from anywhere in the field (that you haven't yet selected) who will finish the greatest number of spots ahead of their starting position.",
        excludePickFromIds: [129, 130, 131, 132],
        options: [
            { id: 1, text: "1st Dixon" }, { id: 2, text: "2nd Herta" }, { id: 3, text: "3rd VeeKay" },
            { id: 4, text: "4th Carpenter" }, { id: 5, text: "5th Kanaan" }, { id: 6, text: "6th Palou" },
            { id: 7, text: "7th Hunter-Reay" }, { id: 8, text: "8th Castroneves" }, { id: 9, text: "9th Ericsson" },
            { id: 10, text: "10th Rossi" }, { id: 11, text: "11th Jones" }, { id: 12, text: "12th O'Ward" },
            { id: 13, text: "13th Fittipaldi" }, { id: 14, text: "14th Rosenqvist" }, { id: 15, text: "15th Sato" },
            { id: 16, text: "16th Hinchcliffe" }, { id: 17, text: "17th McLaughlin" }, { id: 18, text: "18th Rahal" },
            { id: 19, text: "19th Daly" }, { id: 20, text: "20th Harvey" }, { id: 21, text: "21st Newgarden" },
            { id: 22, text: "22nd Hildebrand" }, { id: 23, text: "23rd Ferrucci" }, { id: 24, text: "24th Montoya" },
            { id: 25, text: "25th Andretti" }, { id: 26, text: "26th Pagenaud" }, { id: 27, text: "27th Bourdais" },
            { id: 28, text: "28th Wilson" }, { id: 29, text: "29th Chilton" }, { id: 30, text: "30th Kellett" },
            { id: 31, text: "31st Karam" }, { id: 32, text: "32nd Power" }, { id: 33, text: "33rd De Silvestro" }
        ],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 134,
        layoutId: 2,
        text: "Will the number of international drivers in the Top 5 be over or under 2.5?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 135,
        layoutId: 2,
        text: "Will the winning car number be even or odd?",
        options: [{ id: 1, text: "Even" }, { id: 2, text: "Odd" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 136,
        layoutId: 2,
        text: "Which manufacturer will have more engines in the Top 10 - Honda or Chevy?",
        options: [{ id: 1, text: "Honda" }, { id: 2, text: "Chevy" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 137,
        layoutId: 2,
        text: "Which former Indy 500 champion will place the highest?",
        options: [{ id: 1, text: "Helio Castroneves" }, { id: 2, text: "Scott Dixon" }, { id: 3, text: "Will Power" }, { id: 4, text: "Tony Kanaan" }, { id: 5, text: "Simon Pagenaud" }, { id: 6, text: "Alexander Rossi" }, { id: 7, text: "Ryan Hunter-Reay" }, { id: 8, text: "Takuma Sato" }, { id: 9, text: "Juan Pablo Montoya" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 138,
        layoutId: 2,
        text: "Will the winning car be a car number of 1-20 or 21-98?",
        options: [{ id: 1, text: "1-20" }, { id: 2, text: "21-98" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 139,
        layoutId: 2,
        text: "Which team will have the fastest race lap?",
        options: [{ id: 9, text: "AJ Foyt" }, { id: 1, text: "Andretti" }, { id: 7, text: "Arrow McLaren" }, { id: 11, text: "Carlin" }, { id: 5, text: "Dale Coyne" }, { id: 12, text: "Dreyer-Reinbold" }, { id: 4, text: "Ed Carpenter Racing" }, { id: 2, text: "Ganassi" }, { id: 8, text: "Meyer Shank" }, { id: 13, text: "Paretta" }, { id: 6, text: "Penske" }, { id: 3, text: "Rahal Letterman Lanigan" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 140,
        layoutId: 2,
        text: "Will the number of laps run under caution be under or over 40.5 laps?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 141,
        layoutId: 2,
        text: "Who will be the best rookie finisher?",
        options: [{ id: 1, text: "Pietro Fittipaldi" }, { id: 2, text: "Scott McLaughlin" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 142,
        layoutId: 2,
        text: "Will the number of cars finishing the race be over or under 22.5?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 143,
        layoutId: 2,
        text: "Will the average race speed be over or under 170.5mph?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 144,
        layoutId: 2,
        text: "Will the number of cars finishing on the lead lap be over or under 17.5?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 145,
        layoutId: 2,
        text: "Which grid lane will the first driver out of the race start from?",
        options: [{ id: 1, text: "Inside" }, { id: 2, text: "Middle" }, { id: 3, text: "Outside" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 146,
        layoutId: 2,
        text: "Will the length of the race be over or under 3 hours 0 minutes?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 147,
        layoutId: 2,
        text: "Will the margin of victory be over or under 2.000 seconds?",
        options: [{ id: 1, text: "Over" }, { id: 2, text: "Under" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 148,
        layoutId: 2,
        text: "Will a rookie finish in the top 5?",
        options: [{ id: 1, text: "Yes" }, { id: 2, text: "No" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 149,
        layoutId: 2,
        text: "Which Penske driver will have the best finishing position?",
        options: [{ id: 1, text: "Josef Newgarden" }, { id: 2, text: "Scott McLaughlin" }, { id: 3, text: "Simon Pagenaud" }, { id: 4, text: "Will Power" }],
        value: 1,
        requiredPicks: 1
    },
    {
        id: 150,
        layoutId: 2,
        text: "Which Andretti driver will have the best finishing position?",
        options: [{ id: 1, text: "Alexander Rossi" }, { id: 2, text: "Colton Herta" }, { id: 3, text: "James Hinchcliffe" }, { id: 4, text: "Marco Andretti" }, { id: 5, text: "Ryan Hunter-Reay" }, { id: 6, text: "Stefan Wilson" }],
        value: 1,
        requiredPicks: 1
    }
]