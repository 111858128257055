import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { getFaq } from '../../actions'
import BackButton from '../Speed/FaqBackToIndexButton'
import '../../css/github-markdown.css'
import './css/FaqSingle.css'


export class FaqSingle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faq: {},
            faqId: this.props.location.pathname.split('/').pop(), // get the faqId from the url so we can get the data we want
        }
    }

    componentDidMount = () => {
        this.props.getFaq(this.state.faqId)

        // window.twttr.widgets.load()
    }

    renderFaq() {
        return <div dangerouslySetInnerHTML={{ __html: this.props.faq && this.props.faq.body }}></div>
    }

    render() {
        return (
            <div className="faq-single box">
                <h1 className="faq-single title text">{this.props.faq && this.props.faq.title}</h1>
                {this.renderFaq()}
                <div className="faq-single back-button">
                    <BackButton text="Back to All Questions" to="/indypedia" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        faq: state.faqs.faq
    }
}

export default withRouter(connect(mapStateToProps, {
    getFaq
})(FaqSingle))
