import { combineReducers } from 'redux'
import userReducer from './userReducer'
import newsReducer from './newsReducer'
import gameReducer from './gameReducer'
import setReducer from './setReducer'
import entriesReducer from './entriesReducer'
import faqsReducer from './faqsReducer'
import racesReducer from './racesReducer'

export default combineReducers({
    user: userReducer,
    newsItems: newsReducer,
    game: gameReducer,
    set: setReducer,
    gameEntries: entriesReducer,
    faqs: faqsReducer,
    races: racesReducer
})