import React, { Component } from 'react'
import { connect } from 'react-redux'
import wallpaperImage from '../images/2021-detroit-gp-wallpaper.jpg'

export class Detroit extends Component {
    render() {
        return (
            <div style={{ margin: "20px", display: "flex", flexDirection: "column" }}>
                <div style={{marginBottom:"5px"}}>1. Long-press the image below and Save to your phone.</div>
                <div style={{marginBottom:"5px"}}>2. Go to your Photos (iPhone) or Gallery (Android)</div>
                <div style={{marginBottom:"5px"}}>3. Tap the "Share" icon (iOS) or "Three Dots" (Android) while viewing this photo</div>
                <div style={{marginBottom:"5px"}}>4. Tap "Use as Wallpaper" (iOS) or "Use as/Set as" (Android)</div>
                <div style={{ fontStyle: "italic", marginTop: "15px", maxWidth: "600px" }}>If you're having problems saving the image to your phone, be sure your browser has permissions to save images (in the Privacy app on phone).</div><br />

                <img style={{ height: "25%", width: "25%" }} src={wallpaperImage} alt="2021 Detroit GP Phone Wallpaper" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Detroit)
    