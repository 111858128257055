const def = (state = { faqs: [], faq: {} }, action) => {
    switch (action.type) {
        case "GET_ALL_FAQS":
            return Object.assign({}, { faqs: action.payload });
        case "GET_FAQ":
            return Object.assign({}, { faq: action.payload });
        default:
            return state;
    }
}

export default def;