import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrentRace, getRace } from '../actions'
import { Parser } from 'json2csv';

import '../css/radios.css'
import FileSaver from 'file-saver';
import MerrickSpeedTwitter from './MerrickSpeedTwitter';

export class Radios extends Component {

    constructor(props) {
        super(props)
        // console.log(this.props.match.params)
        this.state = {
            currentRace: {},
            race: {},
            session: {},
            raceNumber: null,
            year: this.props.match.params.year,
            raceName: this.props.match.params.raceName,
            frequencies: []
        }
    }

    componentDidMount = async () => {
        // first, get the current race. we need the `races` array in there in order to get the race number (so we can pull the correct race weekend file (which uses raceNumber -- didn't want to keep it in the route))
        this.setState({ currentRace: await this.props.getCurrentRace() }, async () => {
            const race = this.state.currentRace.races.find(x => x.raceDirectoryName === this.state.raceName);

            // then get the data for whatever race this references (using route params to get it)
            this.setState({
                raceNumber: race.raceNumber,
                race: await this.props.getRace(this.state.year, race.raceNumber, this.state.raceName),
                baseFilename: `${this.state.year}-${race.raceDirectoryName}-radios`
            })
        })
    }

    downloadRadiosJson = () => {
        const blob = new Blob([JSON.stringify(this.state.race.radioFrequencies)], { type: "application/json;charset=utf-8" });
        FileSaver.saveAs(blob, `${this.state.baseFilename}.json`);
    }

    downloadRadiosCsv = () => {
        try {
            const parser = new Parser();
            const csv = parser.parse(this.state.race.radioFrequencies);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(blob, `${this.state.baseFilename}.csv`);
        } catch (err) {
            console.error(err);
        }
    }

    getImageName = () => {
        return `https://superindystorage.blob.core.windows.net/gfx/${this.state.year}/indycar/radios/${this.state.raceNumber}-${this.state.currentRace.raceDirectoryName}-radios.jpg`
    }

    render() {
        return (
            <div className="radio-freqs radio-container">
                <div className="alert alert-info" role="alert">
                    This page is here for you. If it's more helpful to have radio data in a particular format, hit me at <MerrickSpeedTwitter />.
                </div>
                <div className="alert alert-secondary" role="alert">
                    If frequencies are different trackside or you find more (e.g. PA, Race Control), hit me at <MerrickSpeedTwitter />. I'll add it.
                </div>
                <div className="radio-freqs download-container">
                    <h3>Downloads</h3>
                    <div className="radio-freqs download-explainer">Raw data for loading into radios.</div>
                    <button className="btn btn-primary" onClick={this.downloadRadiosJson}>JSON</button>
                    <button className="btn btn-primary ml-2" onClick={this.downloadRadiosCsv}>CSV</button>
                </div>
                <img src={this.state.raceNumber && this.getImageName()} alt={`${this.state.raceShortName} radios`} id="session-results-box" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    getCurrentRace,
    getRace
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Radios))