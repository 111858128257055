import React from 'react'
import styles from './PortfolioItem.module.css'
import { Button, Badge } from 'react-bootstrap'
import { FiletypePdf } from 'react-bootstrap-icons'

export default function PortfolioItem({ number, title, skills, introText, points, slides, files, onLightboxOpen }) {
    return (
        <div className={`${styles.item} mt-2`}>
            <div className={styles.number}>({number})</div>
            <div className={styles.itemBox}>
                <div className={styles.title}>{title}</div>
                <div className={styles.introText}>{introText}</div>
                <div className={styles.tagContainer}>
                    {skills?.length > 0 && skills.map(skill => <Badge className={styles.tag}>{skill}</Badge>)}
                </div>
                <div className={`${styles.actionBar} mt-2`}>
                    {slides?.length > 0 &&
                        <Button type="button" className={styles.button} onClick={() => onLightboxOpen(slides)}>
                            Screenshots
                        </Button>
                    }
                    <div className={`${styles.filesContainer} mt-1`}>
                        {files?.length > 0 &&
                            files.map(file => <div className={styles.file}>
                                <a href={file.src} rel="noreferrer" target="_blank">
                                    <FiletypePdf color="royalblue" size={50} />
                                    <div className={styles.fileName}>{file.title}</div>
                                </a>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
