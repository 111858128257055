import React from 'react'
import moment from 'moment-timezone'
import { rank } from '../../scripts/pickem-helpers'
import firstBy from 'thenby'

export default function ScoreboardSeason({ data, userId }) {
    const players = rank(data.users, "setTotalScore", "seasonRank", -1).sort(firstBy(function(a, b) { return b.setTotalScore - a.setTotalScore }).thenBy("displayName"));

    function renderPlayers() {
        return players.map(player => {
            return (<tr key={player.userId} className={`board-season-row ${userId === player.userId ? 'current-user-scoreboard-row ' : ''}`}>
                <td className="board-season-rank text-right mr-2">{player.seasonRank}</td>
                <td className="board-season-name">{player.displayName}</td>
                <td className="board-season-game-count">{player.gameCount}</td>
                <td className="board-season-score">{player.setTotalScore}</td>
                <td className="board-season-correct-percent">{player.correctPercent}</td>
                <td className="board-season-correct-percent">{player.correctPickedGamesPercent !== player.correctPercent ? player.correctPickedGamesPercent : ""}</td>                
            </tr>)
        }
        )
    }

    return (
        <div className="table-responsive scoreboard-container">
            <div className="scoreboard-match-name">Season Scoreboard</div>
            <div className="season-thru-text">Scores through {data.lastGameInfo.gameName} ({moment(data.lastGameInfo.gameDate).format("MMM D")})</div>
            <table className="table table-small table-board-season mt-2">
                <thead>
                    <tr className="board-season-th-row">
                        <th className="board-season-th board-season-rank-th"></th>
                        <th className="board-season-th board-season-name-th">Name</th>
                        <th className="board-season-th board-season-games-th">Games</th>
                        <th className="board-season-th board-season-score-th">Score</th>
                        <th className="board-season-th">Full %</th>
                        <th className="board-season-th">Self %</th>
                    </tr>
                </thead>
                <tbody>
                    {renderPlayers()}
                </tbody>
            </table>
        </div>
    )
}
