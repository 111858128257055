import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTeamLogoImageUrlFromAbbr, getTeamLogoPath } from '../scripts/pickem-helpers'
import { fetchUserByEntryId, makePropbetPick, updatePropbetPick, fetchGameByGameId } from '../actions'
import moment from 'moment-timezone'
import '../css/pickem.css'
import SpeedTextPlain from './Speed/SpeedTextPlain'
import SpeedTextAreaPlain from './Speed/SpeedTextAreaPlain'
import { Modal } from 'react-bootstrap'

class Picks extends Component {
    constructor(props) {
        super(props)

        let entryId;
        let gameId;
        let pathParts = this.props.history.location.pathname.split('/');
        if (pathParts.length === 4) {
            gameId = pathParts[2];
            entryId = pathParts[3]
        }

        this.state = {
            currentPropBet: {},
            currentPropBetIndex: 0,
            isDonePicking: false,
            hasAllPicksMadeBeenShown: false,
            isDataAvailable: true,
            gameId: this.props.history.location.state?.gameId ?? gameId,
            game: { questions: [] },
            pickDelayBeforeAdvancingMilliseconds: 250,
            entryId,
            isShowDoneModal: false
        }
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.user.id != null)
            this.setState({ isDataAvailable: true })
    }

    componentDidMount = async () => {
        // if we've accessed this entry directly, get the user
        if (this.state.entryId) {
            // console.log(await this.props.fetchUserByEntryId(this.state.entryId));
            await this.props.fetchUserByEntryId(this.state.entryId)
        }

        this.setState({ game: await this.props.fetchGameByGameId(this.state.gameId) }, () => {
            this.setState({ currentPropBet: this.state.game.questions[0], percentQuestionsDone: this.calculatePercentQuestionsDone() });
        })

        // call action creator to get user's info (if entryId != null, GET_USER -- if null, CREATE_USER)
        if (this.props.user.picks.length === this.state.game.questions.length)
            this.setState({
                isDonePicking: true,
                hasAllPicksMadeBeenShown: true
            })
    }

    handleCloseDoneModal = () => {
        this.setState({ isShowDoneModal: false, hasAllPicksMadeBeenShown: true })
    }

    calculatePercentQuestionsDone() {
        const percentQuestionsDone = this.props.user.picks.length / this.state.game.questions.length * 100;
        this.setState({ percentQuestionsDone }, () => {
            this.renderProgressBar();
        });
        return percentQuestionsDone;
    }

    changePropBet = (id) => {
        this.setState({
            currentPropBet: this.state.game.questions.find(x => x.id === id),
            currentPropBetIndex: this.state.game.questions.findIndex(x => x.id === id)
        })

        // if we've already picked all and shown completed message, toggle hasAllPicksMadeBeenShown so the message will be removed and not shown again
        if (this.state.isDonePicking)
            this.setState({ hasAllPicksMadeBeenShown: this.state.isDonePicking ? true : false });
    }

    previousPropBet = (id) => {
        // find the index position of the propbet
        const pbIndex = this.state.game.questions.findIndex(x => x.id === id);
        // can't be null, can't be the first element
        if (pbIndex != null && pbIndex > 0)
            this.setState({ currentPropBet: this.state.game.questions[pbIndex - 1], currentPropBetIndex: this.state.game.questions.findIndex(x => x.id === id) - 1 })

        // if we've alreayd picked all and shown completed message, toggle hasAllPicksMadeBeenShown so the message will be removed and not shown again
        if (this.state.isDonePicking)
            this.setState({ hasAllPicksMadeBeenShown: this.state.isDonePicking ? true : false });
    }

    nextPropBet = (id) => {
        const pbIndex = this.state.game.questions.findIndex(x => x.id === id);

        // has to find a propbet, can't be the final propbet in the array, then advance to next propBet
        if (pbIndex != null && pbIndex !== this.state.game.questions.length - 1) {
            this.setState({
                currentPropBet: this.state.game.questions[pbIndex + 1],
                currentPropBetIndex: this.state.game.questions.findIndex(x => x.id === id) + 1,
                percentQuestionsDone: this.calculatePercentQuestionsDone()
            })
        }

        this.checkIfDonePickingAllPicks();
    }

    checkIfDonePickingAllPicks = () => {
        // if we've selected all the propbets, flip the isDonePicking flag, triggering a message
        // we sum all the `requiredPicks` from the propBets and see if it matches the `selections` of the `user.picks`
        // console.log("pick count", this.props.user.picks.reduce((acc, pick) => { return acc + pick.selections.length }, 0));
        // console.log("required count", this.state.propBets.reduce((acc, propBet) => { return acc + propBet.requiredPicks }, 0));

        if (this.state.game.gameTypeId === 6) {
            if (this.props.user.picks.filter(x => (x.value && x.value.trim() !== "") || x?.selections?.length > 0).length === this.state.game.questions.length) {
                this.setState({ isDonePicking: true, percentQuestionsDone: this.calculatePercentQuestionsDone() }, () => {
                    this.renderProgressBar();

                    if (!this.state.hasAllPicksMadeBeenShown)
                        this.setState({ isShowDoneModal: true })
                })
            }
        } else {
            if (this.props.user.picks.reduce((acc, pick) => { return acc + pick.selections?.length }, 0) === this.state.game.questions.reduce((acc, propBet) => { return acc + parseInt(propBet.requiredPicks) }, 0)) {
                this.setState({ isDonePicking: true, percentQuestionsDone: this.calculatePercentQuestionsDone() }, () => {
                    this.renderProgressBar();
                })
            }
        }
    }

    selectPropBet = (propBet, selectedOption) => {
        // check if we're past the game cutoff. if so, re-direct to scoreboard
        if (this.state.game.gameTypeId !== 6 && this.state.game.cutOffUnixMs < moment().unix() * 1000) {
            this.props.history.push(`/scores/${this.state.gameId}`)
            return;
        }

        // see if the user has already selected this propbet
        let pick = this.props.user.picks.find(x => x.id === propBet.id);

        if (pick) {
            let selections = [...pick.selections];

            // if we have selections already, clear them out (for now -- this isn't a long-term solution)
            if (parseInt(propBet.requiredPicks) === 1 && selections)
                selections = [];

            // check to see if we're selecting or unselecting this pick
            let updatedSelections = [];
            if (selections.includes(selectedOption.id))
                updatedSelections = selections.filter(x => x !== selectedOption.id);
            else {
                updatedSelections = [...selections]
                updatedSelections.push(selectedOption.id);
            }
            pick.selections = updatedSelections;

            this.props.updatePropbetPick(this.props.user, this.state.game.gameTypeId, propBet, updatedSelections, this.checkIfDonePickingAllPicks);

            // should only do this if we're not done picking yet -- after we're done, people will prefer to just cruise through one at a time instead of being forced to the next one
            // some questions can also allow multiple picks. make sure we've picked the limit
            // if (!this.state.isDonePicking && (picksToMake == null || pick.optionId.length === propBet.picksToMake))
            if (!this.state.isDonePicking && (propBet.requiredPicks == null || parseInt(propBet.requiredPicks) === updatedSelections.length)) {
                setTimeout(() => this.nextPropBet(propBet.id), this.state.pickDelayBeforeAdvancingMilliseconds)
            }
        }
        else { // no pick, add it
            this.props.makePropbetPick(this.props.user, this.state.game.gameTypeId, propBet, selectedOption, () => {
                this.checkIfDonePickingAllPicks();

                // if hitting this code, we've JUST made our first pick. The only way we advance to the NEXT prop bet is if there's only 1 required pick, otherwise, we need to make more picks
                if (!this.state.isDonePicking && (propBet.requiredPicks == null || parseInt(propBet.requiredPicks) === 1)) {
                    setTimeout(() => this.nextPropBet(propBet.id), this.state.pickDelayBeforeAdvancingMilliseconds)
                }
            })
        }
    }

    render3up = (propBet, pick, excludePicksFromPropBetIds) => {
        return propBet.options.map(pbo => {
            // class needs to be reset every time
            let className = "propbet-option propbet-option-3up layout-row";

            // if the pick should be excluded, add a class name
            if (excludePicksFromPropBetIds.includes(pbo.id))
                className += " propbet-option-disabled"

            if (pick != null && pick.selections.includes(pbo.id)) {
                className += " propbet-option-selected";
            }

            return (<div key={pbo.id} className={className} onClick={() => this.selectPropBet(propBet, pbo)}>
                <div dangerouslySetInnerHTML={{ __html: pbo.text }}></div>
                <div dangerouslySetInnerHTML={{ __html: pbo.subtext }}></div>
            </div>)
        })
    }

    render1up = (propBet, pick, excludePicksFromPropBetIds) => {
        return propBet.options.map(pbo => {
            let className = "propbet-option";

            // if the pick should be excluded, add a class name
            if (excludePicksFromPropBetIds.includes(pbo.id) || pbo.isDisabled)
                className += " propbet-option-disabled"

            // check to see if this pbo equals the pick we've already made. if no pick, no selection.
            if (pick != null && pick.selections.includes(pbo.id))
                className += " propbet-option-selected";

            className += " layout-column"

            return (<div key={pbo.id} className={className} onClick={() => this.selectPropBet(propBet, pbo)}>
                <div className="propbet-option-text" dangerouslySetInnerHTML={{ __html: pbo.text }}></div>
                <div className="propbet-option-subtext" dangerouslySetInnerHTML={{ __html: pbo.subtext }}></div>
            </div>)
        })
    }

    render2up = (propBet, pick, excludePicksFromPropBetIds) => {
        return propBet.options.map(pbo => {
            let className = "propbet-option-2up";

            // if the pick should be excluded, add a class name
            if (excludePicksFromPropBetIds.includes(pbo.id) || pbo.isDisabled)
                className += " propbet-option-disabled"

            // check to see if this pbo equals the pick we've already made. if no pick, no selection.
            if (pick != null && pick.selections.includes(pbo.id))
                className += " propbet-option-selected";

            className += " layout-column"

            return (<div key={pbo.id} className={className} onClick={() => this.selectPropBet(propBet, pbo)}>
                <div className="propbet-option-text" dangerouslySetInnerHTML={{ __html: pbo.text }}></div>
                <div className="propbet-option-subtext" dangerouslySetInnerHTML={{ __html: pbo.subtext }}></div>
            </div>)
        })
    }

    initializeValuePick = (id) => {
        let pick = {
            id: id,
            value: '',
        }
        this.props.user.picks.push(pick);
        return pick;
    }

    renderTextSingleLine = (propBet, pick) => {
        // if no answer has been entered, create a new object and add it to picks
        if (pick == null) {
            pick = this.initializeValuePick(propBet.id)
        }

        return <SpeedTextPlain type="text" key={pick.id} initialValue={pick.value} style={{ width: "80%" }} placeholder={"Type answer here..."} onTextChanged={(value) => {
            pick.value = value;
            this.props.updatePropbetPick(this.props.user, this.state.game.gameTypeId, propBet, null, () => {}, pick.value);
            this.calculatePercentQuestionsDone();
        }} />
    }

    renderTextMultiLine = (propBet, pick) => {
        // if no answer has been entered, create a new object and add it to picks
        if (pick == null) {
            pick = this.initializeValuePick(propBet.id)
        }

        return <SpeedTextAreaPlain type="text" key={pick.id} initialValue={pick.value} style={{ width: "80%" }} placeholder="Type answer here..." onTextChanged={(value) => {
            pick.value = value;
            this.props.updatePropbetPick(this.props.user, this.state.game.gameTypeId, propBet, null, () => {}, pick.value);
            this.calculatePercentQuestionsDone();
        }} />
    }

    renderQuestionText = (propBet) => {
        return <h4 className="propbet-text" style={(this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown && propBet.selectionTypeId !== 2) ? { display: "none" } : { display: "flex", justifyContent: "center" }} dangerouslySetInnerHTML={{ __html: propBet.text }}></h4>
    }

    renderQuestion = (propBet) => {
        switch (this.state.game.gameTypeId) {
            case 1:
                return this.renderQuestionText(propBet)
            case 2: // Manual Entry
            case 6: // Basic Questions
                return this.renderQuestionText(propBet)
            case 3: // Football
            case 4: // NCAAB
                return this.renderPickemGame(propBet)
            default:
                break;
        }
    }

    renderSportLogoImageElement = (propBet) => {
        const sportName = propBet.game.sportName.includes("ncaa") ? "ncaa" : propBet.game.sportName;
        return <img className="sport-logo-image" src={`/images/logos/sports/${sportName}.svg`} alt={`${sportName} logo`} />
    }

    renderPickemGame = (propBet) => {
        return <div style={{ display: "flex" }}>
            {!this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown && <div className="sport-container" style={{ display: "flex", alignSelf: "flex-start" }}>
                {(this.state.game.gameTypeId === 3 || this.state.game.gameTypeId === 4) && propBet && propBet.gameId && this.renderSportLogoImageElement(propBet)}
            </div>
            }
            <div className="all-game-info-container">{(propBet.layoutId === 5 || propBet.layoutId === 6) && <div key={"question-" + propBet.id} className="game-container" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown ? { display: "none" } : { display: "flex" }}>
                <div className="away-team-container">
                    <div className="team-text-info-container">
                        <div className="team-place">
                            <span className="team-rank">{propBet.game.awayTeam.ranking <= 25 ? propBet.game.awayTeam.ranking : ""} </span>
                            {propBet.game.awayTeam.teamName}
                        </div>
                        <div className="team-nickname">
                            {propBet.game.awayTeam.nickname}
                        </div>
                        <div className="team-record">
                            {propBet.game.awayTeam.record}, Away
                        </div>
                    </div>
                    <img className="team-logo away-team-logo" src={`${getTeamLogoPath(propBet.game)}/${getTeamLogoImageUrlFromAbbr(propBet.game, propBet.game.awayTeam.abbr)}`} alt={propBet.game.awayTeam.teamName.toLowerCase() + " team logo"} />
                </div>
                <div className="game-versus-text">
                    vs
                </div>
                <div className="home-team-container">
                    <img className="team-logo home-team-logo" src={`${getTeamLogoPath(propBet.game)}/${getTeamLogoImageUrlFromAbbr(propBet.game, propBet.game.homeTeam.abbr)}`} alt={propBet.game.homeTeam.teamName.toLowerCase() + " team logo"} />
                    <div className="team-text-info-container">
                        <div className="team-place">
                            <span className="team-rank">{propBet.game.homeTeam.ranking <= 25 ? propBet.game.homeTeam.ranking : ""} </span>
                            {propBet.game.homeTeam.teamName}
                        </div>
                        <div className="team-nickname">
                            {propBet.game.homeTeam.nickname}
                        </div>
                        <div className="team-record">
                            {propBet.game.homeTeam.record}, Home
                        </div>
                    </div>
                </div>
            </div>
            }
                {this.renderQuestionText(propBet)}
            </div>
        </div>
    }


    renderOptions = (propBet) => {
        // find the pick for this propBet
        const pick = this.props.user.picks.find(x => x.id === propBet.id);

        // build a list of any options that should be excluded from this propbet
        let excludePicksFromPropBetIds = [];
        if (propBet.excludePickFromIds != null) {
            // reach back and get all the excluded propBets (based on this propbet's `excludePicksFromPropBetIds`)
            excludePicksFromPropBetIds = this.state.game.questions.filter(pb => propBet.excludePickFromIds.includes(pb.id)).map(excludedPropBet => {
                // get the user options picks for the excluded probets
                return this.props.user?.picks.find(x => x.id === excludedPropBet.id)?.selections;
            }).reduce((a, b) => a.concat(b), []); //<-- flatten the array of arrays (selected options (one array) inside the excluded propbets (the parent array)
        }

        switch (propBet.layoutId) {
            case 1: // horizontal
                return propBet.options.map(pbo => {
                    let className = "propbet-option layout-row";
                    return (<div className={className}></div>);
                })
            case 2: // vertical 1-up
                return this.render1up(propBet, pick, excludePicksFromPropBetIds)
            case 5:
            case 6:
                switch (propBet.selectionTypeId) {
                    case 1: // Yes/No
                        return this.render1up(propBet, pick, excludePicksFromPropBetIds)
                    case 2: // Team Scenarios
                        return this.render1up(propBet, pick, excludePicksFromPropBetIds)
                    case 3: // Team Boxes Only

                        break;
                    default:
                        return this.render1up(propBet, pick, excludePicksFromPropBetIds)
                }
                break;
            case 3: // driver rows (array of arrays)
                return <div className="driver-grid-box-3-up-container">{this.render3up(propBet, pick, excludePicksFromPropBetIds)}</div>
            case 4: // exclude some options from this selection
                return <div className="driver-grid-box-2-up">{this.render2up(propBet, pick, excludePicksFromPropBetIds)}</div>
            case 7:
                return this.render2up(propBet, pick, excludePicksFromPropBetIds)
            case 8: // text - single line
                return this.renderTextSingleLine(propBet, pick)
            case 9: // text - multi line
                return this.renderTextMultiLine(propBet, pick)
            default:
                return (<div></div>);
        }
    }

    getCompletionProgressText(gameTypeId) {
        switch (gameTypeId) {
            case 2: // Manual Entry
            case 5: // Text Only (No Picks)
            case 6: // Basic Questions
                return "Questions Done"
            default:
                return "Picks Made"
        }
    }

    renderPicksMadeText = () => {
        // let fullyPickedCount = 0;
        // this.props.user.picks.forEach(x => {

        // })
        if (this?.props?.user)
            return `${this.props.user.picks.length} of ${this.state.game.questions.length} ${this.getCompletionProgressText(this.state.game.gameTypeId)}`;
    }

    renderProgressBar = (displayType) => {
        return (
            <div className="picks-made-progress">
                <div id={`${displayType}-sidebar-number-completed`}>
                    <div className="picks-made-text">{this.renderPicksMadeText()}</div>
                </div>
                <div className="progress pickem-progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${this.state.percentQuestionsDone}%` }} aria-valuenow={this.state.percentQuestionsDone} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        )
    }

    renderDrivers = () => {
        return this.state.drivers.map(driver => (<div>{driver.lastName}</div>))
    }

    renderPropBetBoxes = (displayType) => {
        // const propertyName = displayType === "mobile" ? "id" : "text";

        return this.state.game.questions.map((pb, idx) => {
            let text = "";

            let className = displayType === "mobile" ? "propbet-sidebar-mobile-item" : "propbet-sidebar-desktop-item";

            // if there's a pick for this propBet in the user's picks, add a css class to mark it selected
            const pick = this.props.user.picks.find(x => x.id === pb.id)
            if (pick && (pick.value || pick.selections?.length === parseInt(pb.requiredPicks)))
                className += " propbet-selected"

            // if this is the CURRENT prop bet we're viewing, mark its border specially
            if (this.state.currentPropBet.id === pb.id)
                className += " propbet-viewing"

            // if the `questionBoxTitle` property is set, default to use that, otherwise, use the Layout Type rules 
            if (pb.questionBoxTitle) {
                text = pb.questionBoxTitle
            } else {
                // depending on the prop bet layout type, we'll render the text differently
                switch (pb.layoutId) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        text = pb.text
                        break;
                    case 5:
                    case 6:
                        // If a Football Game Type, change the text
                        if (this.state.game.gameTypeId === 3 || this.state.game.gameTypeId === 4)
                            text = `${pb.game.awayTeam.teamNameWithRanking} vs ${pb.game.homeTeam.teamNameWithRanking}`
                        break;
                    default:
                        break;
                }
            }

            return (<div key={pb.id} onClick={() => { this.changePropBet(pb.id) }} className={className}>{displayType === "mobile" ? idx + 1 : <span><span className="propbet-question-number">{idx + 1} |</span> {text}</span>}</div>)
        })
    }

    renderWelcomeMessage = () => {
        return <div className="welcome-message">{this.props.user != null ? `Welcome${this.state.game.gameTypeId !== 6 ? ", " + this.props.user.firstName : ""}! | Entry Id: ${this.props.user.id}` : ""}</div>
    }

    render() {
        return (
            <div>
                <div className="col-md-12 col-sm-12" style={this.state.isDataAvailable ? { display: "none" } : { display: "block" }}>Please go to the <Link to="/">Landing Page</Link></div>
                <div className="col-md-12 col-sm-12" style={this.state.isDataAvailable ? { display: "block" } : { display: "none" }}>
                    {this.renderWelcomeMessage()}
                    <div className="row">
                        <div className="col-md-3 d-none d-md-block desktop-sidebar-container">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {this.renderProgressBar('desktop')}
                                {this.renderPropBetBoxes('desktop')}
                            </div>
                        </div>

                        <div className="col-sm-12 d-block d-md-none mobile-prop-box-container">
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                {this.renderPropBetBoxes('mobile')}
                            </div>
                            {this.renderProgressBar('mobile')}
                        </div>

                        <div className="col-lg-6 col-md-8 col-sm-12" id="prop-bet-question-container">
                            <div className="sticky-top">
                                {this.renderQuestion(this.state.currentPropBet)}
                                {(this.state.currentPropBet.subtext !== null ? <small className="propbet-subtext" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown ? { display: "none" } : { display: "block" }} dangerouslySetInnerHTML={{ __html: this.state.currentPropBet.subtext }}></small> : "")}
                                <div className="propbet-points" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown ? { display: "none" } : { display: "block" }}>{this.state.currentPropBet.value && this.state.currentPropBet.value + (parseInt(this.state.currentPropBet.value) === 1 ? " point" : " points")}</div>

                                <div className="row" id="pick-options-container" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown ? { display: "none" } : { display: "flex" }}>
                                    <div className="col-md-12 col-sm-12 pick-options-flex mt-2 mb-3" align="center">
                                        {this.renderOptions(this.state.currentPropBet)}
                                    </div>
                                </div>

                                <div className="all-picks-made" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown && this.state.game.gameTypeId !== 6 ? { display: "flex", flex: "0 0 auto" } : { display: "none" }}>
                                    <i className="fas fa-flag-checkered flag-large"></i>
                                    <div className="finished-picks">You're done! Feel free to review/change your picks. To change them later, write down your entry id and enter it on the landing page by clicking <b>Update Entry</b>.
                                        <div className="finished-picks">Scoreboard Open: {moment(this.state.game.cutOffUnixMs).tz(moment.tz.guess()).format("dddd, h:mma z")} at <a href={`https://superindy.com/scores/${this.state.gameId}`} target="_blank" rel="noreferrer">https://superindy.com/scores/{this.state.gameId}</a></div>
                                        <div className="finished-picks">Scoring Finished: {moment(this.state.game.scoresReadyTimeUnixMs).tz(moment.tz.guess()).format("dddd, h:mma z")}</div>
                                        {this.state.game.isShowTwitter && <div className="finished-picks">Results will be posted to my <a href="https://twitter.com/merrickspeed" target="_blank" rel="noreferrer">Twitter</a> also.</div>}
                                        <div className="finished-picks">Peace! 👊🏼</div>
                                    </div>
                                    <br />
                                    <div className="finished-entry-id-label">Your Entry Id:</div>
                                    <div className="finished-entry-id-text">{this.props.user.id}</div>
                                </div>

                                <div id="previous-next-container" style={this.state.isDonePicking && !this.state.hasAllPicksMadeBeenShown ? { display: "none" } : { display: "flex" }}>
                                    <div className="left-pickem-footer">
                                        <Button className="pickem-footer-previous-button" onClick={() => this.previousPropBet(this.state.currentPropBet.id)} disabled={this.state.game.questions[0] && this.state.currentPropBet.id === this.state.game.questions[0].id ? 'disabled' : ''}>Previous</Button>
                                    </div>
                                    <div className="center-pickem-footer">
                                        {/* <div id="propbet-number">{this.state.currentPropBetIndex + 1} of {this.state.game.questions.length}</div> */}
                                    </div>
                                    <div className="right-pickem-footer">
                                        <Button onClick={() => this.nextPropBet(this.state.currentPropBet.id)} disabled={this.state.currentPropBet.id === this.state.game.questions.length ? 'disabled' : ''}>Next</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>{JSON.stringify(this.props.user)}</div> */}
                </div>
                <Modal show={this.state.isShowDoneModal} onHide={() => this.handleCloseDoneModal()}>
                    <Modal.Header closeButton>
                        <></>
                    </Modal.Header>
                    <Modal.Body>
                        All done! Thanks so much for taking the time to answer these questions.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleCloseDoneModal() }}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    fetchUserByEntryId,
    makePropbetPick,
    updatePropbetPick,
    fetchGameByGameId
})(Picks)