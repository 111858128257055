import React, { Component } from 'react'
// import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { getFaqs } from '../../actions'
import '../../css/faqs.css'

export class FaqList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faqs: []
        }
    }

    componentDidMount = () => {
        this.props.getFaqs();
    }

    renderFaqList() {
        return <table className="faq list-table">
                <thead>
                    <tr>
                        {/* <th>Question</th> */}
                    </tr>
                </thead>
                <tbody>
                    {this.props.faqs && this.props.faqs.filter(x => !x.isHide).map(x => {
                        return <tr key={x.id}>
                            <td>
                                <div className="faq-list question box">
                                    {/* <Helmet>
                                        <meta name="description" content={`SuperIndy | ${x.description}`} />
                                        <meta name="og:title" content={x.title} />
                                        <meta name="og:description" content={`SuperIndy | ${x.description}`} />
                                        <meta name="og:image" content={x.thumbnail} />
                                    </Helmet> */}
                                    <Link to={`/indypedia/${x.id}`} className="faq list-table question text">{x.title}</Link>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
    }

    render() {
        return (
            <div className="col-12">
                <h2 className="libre">Indypedia</h2>
                <div className="header-explainer">A growing set of in-depth IndyCar info. New entries daily.</div>
                <div className="faq-list box">
                    {this.renderFaqList()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        faqs: state.faqs.faqs
    }
}

export default withRouter(connect(mapStateToProps, {
    getFaqs
})(FaqList))
