import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SpeedTextInput from './Speed/SpeedTextInput';
import '../css/twitter-spaces.css'

export default function TwitterSpacesDownload() {
    return (
        <div className="container standard">
            <h2>Spaces Down</h2>
            <Formik
                initialValues={{
                    url: ''
                }}
                validationSchema={Yup.object({
                    url: Yup.string()
                        .matches(
                            /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/,
                            "Must be full tweet url (e.g. https://twitter.com/username/status/12345)"
                        )
                        .required('Tweet url required.'),
                })}
            // onSubmit={(values, { setSubmitting }) => {
            //     setTimeout(() => {
            //         alert(JSON.stringify(values, null, 2));
            //         setSubmitting(false);
            //     });
            // }}
            >
                {({ errors, values, touched, setValues }) => (
                    <Form>
                        <SpeedTextInput
                            label=""
                            name="url"
                            type="text"
                            placeholder="Tweet url"
                        />

                        <a href={`/api/spaces/download?u=${values.url}`} download target="_self" className={values.url === "" || errors.url ? "btn disabled-link" : "btn btn-primary"}>Download
                        </a>
                    </Form>
                )}
            </Formik>
        </div>
    )
}