import React, { Component } from 'react'
import { Helmet } from "react-helmet";
// import Route from './Route'
// import Landing from './Landing'
import Registration from './Registration'
import RegistrationFamily from './RegistrationFamily'
import Picks from './Picks'
import Header from './Header'
import FaqList from './faqs/FaqList'
import FaqSingle from './faqs/FaqSingle'
import Radios from './Radios'
import SessionResults from './SessionResults'
// import DriverPicker from './DriverPicker'
import About from './About'
// import RaceGrid from './RaceGrid'
// import RaceTimes from './RaceTimes'
import Indy500 from './Indy500'
import LiveriesSpecial from './LiveriesSpecial'
import Detroit from './Detroit'
import Hi from './Hi'
// import Notes from './Notes'
import Scoreboard from './Scoreboard'
import IndyCar from './IndyCar'
import Landing from './Sets/SetLanding'
import Set from './Sets/Set'
// import NoMatch from './NoMatch'
// import Pylon from './Pylon'
// import Ncaa from './Ncaa'
// import Juxtapose from './Juxtapose'
import NewsListing from './NewsListing'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import '../css/indycar.css'
import TwitterSpacesDownload from './TwitterSpacesDownload'
import BookTracker from './BookTracker/BookTracker';
import PayKonnectIntro from './PayK/PayKonnectIntro'

export default class App extends Component {
    render() {
        // const api_regex = /^\/apiaaaa\/.*/
        // // if using "/api/" in the pathname, don't use React Router
        // if (api_regex.test(window.location.pathname)) {
        //     console.log("HIT");
        //     return <></> // must return at least an empty div
        // } else {
        return (
            <div className="container-fluid">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="SuperIndy by @merrickspeed" content="" />
                </Helmet>
                <BrowserRouter>
                    <Header />
                    <div className="superindy-container">
                        <Switch>
                            <Route path="/" exact component={Hi} />
                            {/* <Route path="/grid" exact component={RaceGrid} /> */}
                            <Route path="/about" exact component={About} />
                            <Route path="/pickem" component={Registration} />
                            <Route path="/football" component={RegistrationFamily} />
                            <Route path="/go" component={Picks} />
                            <Route path="/games" component={RegistrationFamily} />
                            <Route path="/survey" component={RegistrationFamily} />
                            <Route path="/picks" component={Picks} />
                            <Route path="/landing" component={Landing} />
                            <Route path="/sets" component={Set} />
                            <Route path="/books" component={BookTracker} />
                            {/* <Route path="/grid" component={RaceGrid} /> */}
                            {/* <Route path="/sessions" component={RaceTimes} /> */}
                            <Route path="/scores" component={Scoreboard} />
                            <Route path="/indypedia" exact component={FaqList} />
                            <Route path="/indypedia/:id" component={FaqSingle} />
                            <Route path="/indycar" component={IndyCar} />
                            <Route path="/races/:year/:raceName/radios" component={Radios} />
                            <Route path="/results/:year/:raceName/:sessionName/:resultType" component={SessionResults} />
                            <Route path="/wallpaper" component={Indy500} />
                            <Route path="/special-liveries" component={LiveriesSpecial} />
                            <Route path="/detroit" component={Detroit} />
                            <Route path="/spaces" exact component={TwitterSpacesDownload} />
                            <Route path="/payk" exact component={PayKonnectIntro} />
                            {/* <Route path='/api/spaces/download2' component={() => {
                                window.location.href = 'http://localhost:3002/api/spaces/download';
                                return null;
                            }} /> */}
                            {/* <Route path="/grid" component={RaceGrid} /> */}
                            {/* <Route path="/sessions" component={RaceTimes} /> */}
                            {/* <Route path="/pylon" component={Pylon} /> */}
                            {/* <Route path="/ncaa" component={Ncaa} /> */}
                            {/* <Route path="/nashville2020" component={Juxtapose} /> */}
                            {/* <Route exact path="/scores/:entryId" component={Scoreboard} /> */}
                            {/* <Route exact path="/scores" component={Scoreboard} /> */}
                            <Route path="/news" component={NewsListing} />
                            {/* <Route path="/derby" component={Landing} /> */}
                            {/* <Route path="*">
                                <NoMatch />
                            </Route> */}
                            {/* <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    /> */}
                        </Switch>
                    </div>
                </BrowserRouter>
            </div>
        )
    }
    // }
}
