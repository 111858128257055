import localApi from '../apis/local'
import { getRandomString, getPickTeam, getTeamLogoImageUrlFromAbbr } from '../helpers';

export const createUser = (uiUser) => async dispatch => {
    return new Promise(async (resolve, reject) => {
        // create 5-digit code -- assume right now we're not going to have collisions
        // TODO: ensure we're not generating a duplicate entryId
        const entryId = getRandomString(5);
        const user = { ...uiUser, id: entryId, picks: [], created: (Date.now() / 1000).toFixed(0) }

        // send the user to the db
        const dbUser = await localApi.post(`/user`, { user })

        // dispatch the action
        dispatch({
            type: "CREATE_USER",
            payload: dbUser.data
        })
        resolve(dbUser.data);
    })
}

export const fetchUserByEntryId = (entryId) => async dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await localApi.get(`/user/${entryId}`)
        // manually dispatch after the async api call returns (uses redux-thunk)
        dispatch({ type: "FETCH_USER_BY_ENTRY_ID", payload: response.data })
        resolve(response.data);
    })
}

export const fetchEntriesByPickemGameId = (gameId) => async dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await localApi.post(`/users/${gameId}`, { $and: [{ gameId }, { $or: [{ isTest: null }, { isTest: false }] }] })

        // manually dispatch after the async api call returns (uses redux-thunk)
        dispatch({ type: "FETCH_ENTRIES_BY_GAME_ID", payload: response.data })
        resolve(response.data);
    })
}

export const updateUser = (user) => async dispatch => {

    const response = await localApi.put(`/user`, { user })

    // manually dispatch after the async api call returns (uses redux-thunk)
    dispatch({ type: "UPDATE_USER", payload: response.data })
}

export const makePropbetPick = (user, gameTypeId, propBet, selectedOptions, isDoneCallback) => async dispatch => {
    let selections = [];
    if (typeof selectedOptions === "object")
        selections = [selectedOptions.id]
    else
        selections = [selectedOptions.map(x => x.id)]

    // create an artificial pick from the selection (`selections` is what the `getPickTeam` uses to get the team)
    const pick = { selections }

    // get the picked team abbr
    let pickedTeamAbbr;
    if (gameTypeId === 3 || gameTypeId === 4)
        pickedTeamAbbr = getPickTeam(propBet, pick);

    const userWithNewPick = {
        ...user,
        picks: [
            ...user.picks,
            {
                id: propBet.id,
                selections,
                pickTime: (Date.now() / 1000).toFixed(0),
                pickedTeamAbbr,
                pickedTeamLogoImageUrl: gameTypeId === 3 || gameTypeId === 4 ? getTeamLogoImageUrlFromAbbr(propBet.game, pickedTeamAbbr) : null
            }
        ],
        lastUpdated: (Date.now() / 1000).toFixed(0)
    }
    // console.log("user with new pick from action", userWithNewPick);
    const response = await localApi.put(`/user`, userWithNewPick)

    // manually dispatch after the async api call returns (uses redux-thunk)
    dispatch({ type: "PROPBET_SELECTED", payload: response.data })

    isDoneCallback();
}

/**
 * 
 * @param {User} user 
 * @param {PropBet} propBet 
 * @param {Array} selectedOptions Ids of the picks in an Array
 * @param {*} isDoneCallback 
 * @returns 
 */
export const updatePropbetPick = (user, gameTypeId, propBet, selectedOptions, isDoneCallback, value) => async dispatch => {

    // create an artificial pick from the selection (`selections` is what the `getPickTeam` uses to get the team)
    const teamSelectionPick = { selections: selectedOptions }

    // get the picked team abbr
    let pickedTeamAbbr;
    if (gameTypeId === 3 || gameTypeId === 4)
        pickedTeamAbbr = getPickTeam(propBet, teamSelectionPick);

    const index = user.picks.findIndex(x => x.id === propBet.id);
    // 1. Make a shallow copy of the items
    let picks = [...user.picks];

    // 2. Make a shallow copy of the item you want to mutate and change any properties you want changed
    let pick = {
        ...picks[index],
        selections: selectedOptions,
        value,
        lastUpdated: (Date.now() / 1000).toFixed(0),
        pickedTeamAbbr,
        pickedTeamLogoImageUrl: gameTypeId === 3 || gameTypeId === 4 ? getTeamLogoImageUrlFromAbbr(propBet.game, pickedTeamAbbr) : null
    };

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    picks[index] = pick;

    // 5. Set the state to our new copy
    const userWithUpdatedPick = {
        ...user,
        picks,
        lastUpdated: (Date.now() / 1000).toFixed(0)
    }

    const response = await localApi.put(`/user`, userWithUpdatedPick)

    // manually dispatch after the async api call returns (uses redux-thunk)
    dispatch({
        type: "PROPBET_UPDATED", payload: response.data
    })

    isDoneCallback();
}

export const fetchGameByGameId = (gameId) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        // check redux to see if this game has already been fetched. if so, use it
        const game = getState().game;

        if (game.id)
            resolve(game)
        else { // game for this game id hasn't been fetched yet
            const response = await localApi.get(`/games/${gameId}`)

            // manually dispatch after the async api call returns (uses redux-thunk)
            dispatch({ type: "FETCH_GAME", payload: response.data })
            resolve(response.data);
        }
    })
}

export const fetchSetBySetId = (setId) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        // check redux to see if this game has already been fetched. if so, use it
        const set = getState().set;

        if (set.id)
            resolve(set)
        else { // game for this game id hasn't been fetched yet
            const response = await localApi.get(`/sets/${setId}`)

            // manually dispatch after the async api call returns (uses redux-thunk)
            dispatch({ type: "FETCH_SET", payload: response.data })
            resolve(response.data);
        }
    })
}

export const getGameEntryCountByGameId = (gameId) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        // always fetch the updated count of entries
        const response = await localApi.get(`/users/count/${gameId}`)

        // manually dispatch after the async api call returns (uses redux-thunk)
        dispatch({ type: "GET_GAME_ENTRY_COUNT", payload: response.data })
        resolve(response.data);
    })
}

export const getCurrentlyAvailableGames = () => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        // always fetch the updated count of entries
        const response = await localApi.get(`/abc`)
        console.log("resp", response);
        // manually dispatch after the async api call returns (uses redux-thunk)
        dispatch({ type: "CURRENTLY_AVAILABLE_GAMES", payload: response.data })
        resolve(response.data);
    })
}

export const getFaqs = () => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        // always fetch the updated count of entries
        const response = await localApi.get(`/faqs`)

        // manually dispatch after the async api call returns (uses redux-thunk)
        dispatch({ type: "GET_ALL_FAQS", payload: response.data })
        resolve(response.data);
    })
}

export const getFaq = (id) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        id = parseInt(id)

        // check redux to see if this game has already been fetched. if so, use it
        let faq = getState().faqs.faqs.find(x => x.id === id);

        if (!faq) {

            // await getFaqs();
            const response = await localApi.get(`/faqs`)

            // manually dispatch after the async api call returns (uses redux-thunk)
            dispatch({ type: "GET_ALL_FAQS", payload: response.data })
            faq = getState().faqs.faqs.find(x => x.id === id);
        }
        
        dispatch({ type: "GET_FAQ", payload: faq })
        
        resolve(faq)
    })
}

export const getCurrentRace = () => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const response = await localApi.get(`/races/current`)

        dispatch({ type: "GET_CURRENT_RACE", payload: response.data })

        resolve(response.data);
    })
}

export const getRace = (year, raceNumber, raceDirectoryName) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const response = await localApi.post(`/races/detail`, { year, raceNumber, raceDirectoryName })

        dispatch({ type: "GET_RACE", payload: response.data })

        resolve(response.data);
    })
}

export const getSetScoreboard = (setId) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const response = await localApi.get(`/pickem/scoreboards/sets/${setId}`)

        dispatch({ type: "SET_SCOREBOARD", payload: response.data })

        resolve(response.data);
    })
}