import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrentRace, getRace } from '../actions'
import { getLowercaseSessionNameFromSessionName } from '../helpers'
import moment from 'moment-timezone'

import '../css/session-results.css'

export class SessionResults extends Component {

    constructor(props) {
        super(props)
        // console.log(this.props.match.params)
        this.state = {
            currentRace: {},
            race: {},
            session: {},
            raceNumber: null,
            year: this.props.match.params.year,
            raceName: this.props.match.params.raceName,
            sessionName: this.props.match.params.sessionName,
            resultType: this.props.match.params.resultType,
        }
    }

    componentDidMount = async () => {
        // first, get the current race. we need the `races` array in there in order to get the race number (so we can pull the correct race weekend file (which uses raceNumber -- didn't want to keep it in the route))
        this.setState({ currentRace: await this.props.getCurrentRace() }, async () => {
            const race = this.state.currentRace.races.find(x=>x.raceDirectoryName === this.state.raceName);

            // then get the data for what whatever race this references (using route params to get it)
            this.setState({ raceNumber: race.raceNumber, race: await this.props.getRace(this.state.year, race.raceNumber, this.state.raceName) }, () => {
                // then get the session we want off this race (from the route)
                this.setState({ session: this.state.race.sessions.find(x=> getLowercaseSessionNameFromSessionName(x.sessionName) === this.state.sessionName ) })
            })
        })
    }

    getImageName = () => {
        return `https://superindystorage.blob.core.windows.net/gfx/${this.state.year}/indycar/session-results-${this.state.resultType}/${moment(this.state.session.sessionStartDate).format('YYYYMMDD')}-${this.state.raceNumber}-${this.state.raceName}-${getLowercaseSessionNameFromSessionName(this.state.sessionName)}-${this.state.resultType}.jpg`
    }

    render() {
        return (
            <div>
                {/* {this.state.session.sessionName} */}
                <img src={this.getImageName()} alt={`Session results`} id="session-results-box" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    getCurrentRace,
    getRace
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionResults))