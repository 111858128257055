import React from 'react'

export default function Link({className, href, children}) {
    const onClick = (e) => {

        // if user hits command+click or ctrl+click to open new tab, just return and don't do any of this.
        if (e.metaKey || e.ctrlKey) {
            return;
        }

        // prevent entire page from reloading in browser
        e.preventDefault(); 

        // push the url into the browser's history/url bar
        window.history.pushState({}, '', href);

        // raise an event that a nav event has occurred.
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
    }

    return (
        <a onClick={onClick} href={href} className={className}>{children}</a>
    )
}
